import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import Copy from "components/Copy";
import { Formik, Form } from "formik";
import { useAlert } from "components/hooks";
import { useMutation } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import { replaceSpaceWithDash } from "helpers/func";
import { CustomButton } from "components/Utilities";
import { FormikControl } from "components/validation";
import { updatePartnerSubdomain, updateWidgetConfiguration } from "components/graphQL/Mutation";

export const createWebappLinkFormValidationSchema = Yup.object({
  brandName: Yup.string("Enter your Brand name")
    .trim()
    .required("Brand name is required"),
  brandColor: Yup.string("Pick your preferred color")
    .trim()
    .required("Brand color is required"),
  brandLogo: Yup.string("upload your preferred Logomark")
    .trim(),
});

const CreateWebappLinkForm = ({ accountInfo, initialValues, refreshInfo, widgetScriptType }) => {
  const theme = useTheme();
  const { accountID } = accountInfo;
  const [webappUrl, setWebappUrl] = useState(null);
  const { getErrorMsg, displayMessage } = useAlert();
  const baseUrl = process?.env?.REACT_APP_WEBLINK_BASE_URL;
  const [isGenerated, setIsGenerated] = useState(false);
  const [updatePartnerWebLink] = useMutation(updatePartnerSubdomain);
  const [updateWidgetConfig] = useMutation(updateWidgetConfiguration);

  const buttonType = {
    background: theme.palette.common.black,
    hover: theme.palette.primary.main,
    active: theme.palette.primary.dark,
  };

  const onSubmit = async (values) => {
    try {
      const { brandName, brandColor, brandLogo} = values;
      const webLinkVariables = {
        partnerId: accountID,
        subdomain: replaceSpaceWithDash(`${brandName}`.toLowerCase()),
      }

      const widgetVariables = {
        partnerId: accountID,
        widgetColor: brandColor,
        widgetLogo: brandLogo,
        category: widgetScriptType,
      }

      const webLinkData = initialValues?.brandName === "" ? await updatePartnerWebLink({variables: webLinkVariables}): null;
      const widgetData = await updateWidgetConfig({variables: widgetVariables});
  
      if(webLinkData?.data && widgetData?.data){
        const subdomain = webLinkData?.data?.updatePartnerSubdomain?.subdomain;
        setWebappUrl(`${baseUrl}/${subdomain}`);
        setIsGenerated(true);
        refreshInfo();
      }

      if(widgetData?.data && initialValues?.brandName !== ""){
        const subdomain = initialValues?.brandName;
        setWebappUrl(`${baseUrl}/${subdomain}`);
        setIsGenerated(true);
        refreshInfo();
      }
    } catch (error) {
      console.error(error);
      const errMsg = getErrorMsg(error);
      displayMessage("error", errMsg);
    }
  };

  useEffect(()=>{
    if(initialValues?.brandName !== "" && baseUrl) {
        setWebappUrl(`${baseUrl}/${initialValues?.brandName}`);
        setIsGenerated(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid>
      {isGenerated ? (
        <Grid>
          {webappUrl ? (
            <>
              <Typography>
                Copy the Subdomain below and paste in your browser.
              </Typography>
              <Grid sx={{ display: "flex", position: "relative", margin: "2rem 0rem" }}>
                <code
                  style={{
                    width: "100%",
                    backgroundColor: "rgba(191, 201, 226, 0.5)",
                    color: "rgba(62, 94, 169, 1)",
                    border: "1px solid rgba(62, 94, 169, 1)",
                    borderRadius: "5px",
                    padding: "1rem",
                    maxHeight: "200px",
                  }}
                >
                  {webappUrl}
                </code>
                <Grid sx={{ position: "absolute", top: "0.5rem", right: "1.5rem" }}>
                  <Copy
                    text={webappUrl}
                    name="Link"
                    size="large"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
              <CustomButton
                title="Edit"
                width="100%"
                type={buttonType}
                onClick={() => setIsGenerated(false)}
              />
          </Grid>
            </>
          ) : (
            <Grid>
            <Typography>
              No subdomain script. Please click on the button below.
            </Typography>
            <Grid item xs={12}>
            <CustomButton
              title="Regenerate"
              width="100%"
              type={buttonType}
              onClick={() => setIsGenerated(false)}
            />

          </Grid> 
          </Grid>
          )}
        </Grid>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => onSubmit(values)}
          validationSchema={createWebappLinkFormValidationSchema}
          validateOnChange={true}
          validateOnMount={false}
          validateOnBlur={true}
        >
          {({ isSubmitting, setFieldValue }) => {
            return (
              <Form style={{ marginTop: "1rem" }}>
                <Grid item container direction="column" gap={1}>
                  <Grid item container rowSpacing={2}>

                  <Grid item container>
                      <FormikControl
                        control="input"
                        name="brandName"
                        label="Brand name"
                        placeholder="Name of your Brand/Company."
                        disabled={initialValues?.brandName ? true : false}
                      />
                    </Grid>

                    <Grid item container>
                      <FormikControl
                        control="input"
                        name="brandColor"
                        label="Brand color (Color name or HEX or RGBA)"
                        placeholder="red or #000000 or rgba(255, 255, 255, 0%)"
                      />
                    </Grid>

                    <Grid item container>
                      <FormikControl
                        control="file"
                        name="brandLogo"
                        label="Full logo"
                        setFieldValue={setFieldValue}
                        hasTooltip
                        tooltip="A Full logo (wordmark) is usually a distinct text-only typographic treatment of the name of a company, institution, or product name used for purposes of identification and branding. Wordmark have the company name attached. E.g FedEx and Google."
                        tooltipUrl="https://www.google.com/search?q=what+is+a+workmark"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <CustomButton
                        title="Generate"
                        type={buttonType}
                        width="100%"
                        isSubmitting={isSubmitting}
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
    </Grid>
  );
};

CreateWebappLinkForm.propTypes = {
  accountInfo: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default CreateWebappLinkForm;
