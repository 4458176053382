import React from "react";
import PropTypes from "prop-types";
import { Typography, Grid } from "@mui/material";
import { Modals, CustomButton } from "components/Utilities";
import { useTheme } from "@mui/material/styles";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";

const Success = ({
  open,
  handleDialogClose,
  title,
  confirmationMsg,
  btnValue,
  onConfirm,
  onCancel,
  titleHeader,
  ...rest
}) => {
  const theme = useTheme();

  const disableButton = {
    background: "#3E5EA9",
    hover: "#3E5EA9",
    active: "#3E5EA9",
  };

  return (
    <Modals
      isOpen={open}
      title={titleHeader ? titleHeader : ""}
      rowSpacing={3}
      handleClose={handleDialogClose}
    >
      <Grid item container direction="column" rowSpacing={3}>
        <Grid item container justifyContent="center">
          <CheckCircleSharpIcon sx={{ fontSize: "8rem", color: "green" }} />
        </Grid>
        <Grid item container justifyContent="center">
          <Typography variant="h2" sx={{ color: "#2C2C2C", fontSize: "3rem" }}>
            {title}
          </Typography>
        </Grid>
        <Grid item container justifyContent="center">
          <Typography
            variant="body1"
            sx={{ color: "#6C6C6C", fontSize: "1.8rem" }}
          >
            {confirmationMsg}
          </Typography>
        </Grid>
        <Grid item container marginTop={6}>
          <CustomButton
            title={btnValue}
            type={disableButton}
            width="100%"
            onClick={handleDialogClose}
            borderRadius="6.6rem"
          />
        </Grid>
      </Grid>
    </Modals>
  );
};

Success.propTypes = {
  open: PropTypes.bool,
  handleDialogClose: PropTypes.func,
  title: PropTypes.string,
  confirmationMsg: PropTypes.string,
  titleHeader: PropTypes.string,
  btnValue: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

export default Success;
