import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import TableLayout from "components/layouts/TableLayout";
import { NoData, EmptyTable } from "components/layouts";
import { Formik, Form } from "formik";
import { EnhancedTable } from "components/layouts";
import { ref2 } from "components/Utilities/tableHeaders";
// import { defaultPageInfo, searchOptions } from "helpers/mockData";
import { TableCell, TableRow, Typography, Grid } from "@mui/material";
import { CustomButton, Profile } from "components/Utilities";
import ArrowUp from "components/Icons/ArrowUp";
import { useHistory } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
const useStyles = makeStyles((theme) => ({
  gridsWrapper: {
    background: "#fff",
    borderRadius: "1rem",
    minHeight: "15rem",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.2)",
  },

  cardsWrapper: {
    "&.MuiGrid-root > *": {
      flexWrap: "wrap",
    },
  },
  card: {
    "&.MuiGrid-root": {
      height: "100%",
      borderRadius: "1rem",
      flexDirection: "column",

      gap: "1rem",
    },
  },

  chipRoot: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    wordBreak: "break-all",
    fontSize: "clamp(1.2rem, 3vw, 1.3rem) ",
    color: theme.palette.common.green,
    border: "1px solid #bdbdbd",
    padding: ".4rem",
    maxWidth: "max-content",
    minHeight: "2rem",
  },
  chipLabel: {
    overflowWrap: "break-word",
    whiteSpace: "nowrap",
    textOverflow: "clip",
  },
  testLabel: {
    background: "#E6E6E6",
    color: "#979797",
    borderColor: "transparent",
    fontSize: "1rem",
    padding: "2rem",
    borderRadius: "2.6rem",
  },

  cardContainer: {
    "&.MuiGrid-root": {
      display: "grid",
      gap: "2rem",

      "& > *": {
        flex: 1,
        boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.2)",
        minHeight: "14.9rem",
        background: "#fff",
      },
    },
  },
  buttonsGridWrapper: {
    marginTop: "5rem !important",
  },

  title: {
    "&.MuiTypography-root": {
      color: theme.palette.common.grey,
    },
  },
}));
const MedicationDetails = (pendingProfile) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [pageInfo, setPageInfo] = useState({
    page: 0,
    totalPages: 1,
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    totalDocs: 0,
  });
  const [profiles, setProfiles] = useState(
    Array(5).fill({
      date: "Paracetamol",
      test: 2,
      amount: "N200:00",
    })
  );
  const buttonType = {
    background: "#ECEFF6",
    hover: "#ECEFF6",
    active: "#ECEFF6",
  };
  const buttonTypess = {
    background: "#3E5EA9",
    hover: "#3E5EA9",
    active: "#3E5EA9",
  };
  const buttonTypes = {
    background: "#FBEBEB",
    hover: "#FBEBEB",
    active: "#FBEBEB",
  };
  const {
    createdAt,
    gender,
    sampleCollection,
    referralId,
    reason,
    testId,
    userLocation,
    status,
    doctor,
    tests,
    affiliation,
    testOption,
    doctorData,
    patientData,
    partnerData,
    // eslint-disable-next-line
  } = pendingProfile;

  return (
    <Grid container direction="column" gap={4} style={{ paddingBlock: "5rem" }}>
      <Profile
        createdAt={"createdAt"}
        gender={"Female"}
        referralId={"Xmudwwwdw5"}
        reason={"Diagnostics Test"}
        status={"Pending"}
        doctor={"doctor"}
        affiliation={"affiliation"}
        testOption={testOption}
        doctorData={"doctorData"}
        patientData={"patientData"}
        partnerData={"partnerData"}
        type="pending"
      />

      <TableLayout noSearch={true}>
        {profiles.length > 0 ? (
          /* ================= PATIENTS TABLE ================= */
          <Grid
            container
            item
            direction="column"
            overflow="hidden"
            maxWidth={{ md: "100%", sm: "100%", xs: "100%" }}
          >
            <EnhancedTable
              headCells={ref2}
              rows={profiles}
              paginationLabel="Patients per page"
              hasCheckbox={false}
              //   changeLimit={async (e) => {
              //     const res = changeHospitalTableLimit(fetchPatient, {
              //       first: e,
              //       providerId: id,
              //     });
              //     await setTableData(res, "Failed to change table limit.");
              //   }}
              dataPageInfo={pageInfo}
              //   handlePagination={async (page) => {
              //     const res = handleHospitalPageChange(
              //       fetchPatient,
              //       page,
              //       pageInfo,
              //       id
              //     );
              //     await setTableData(res, "Failed to change page.");
              //   }}
            >
              {profiles.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                // const {
                //   dociId,
                //   firstName,
                //   lastName,
                //   plan,
                //   provider,
                //   //image,
                //   consultations,
                //   _id,
                //   status,
                // } = row;
                const { date, orderId, doctor, patient, amount, test } = row;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    style={{ cursor: "pointer" }}
                    // onClick={() => history.push(`medical/${index}`)}
                  >
                    <TableCell
                      id={labelId}
                      scope="row"
                      align="left"
                      className={classes.tableCell}
                      style={{
                        color: theme.palette.common.grey,
                        textAlign: "left",
                      }}
                    >
                      {date}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {/* {orderId} */} 2
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {/* {doctor} */} N300:00
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCell}
                      data-testid="test-value"
                    >
                      {/* {patient} */} ...
                    </TableCell>
                  </TableRow>
                );
              })}
            </EnhancedTable>
          </Grid>
        ) : (
          <EmptyTable headCells={ref2} paginationLabel="Test per page" />
        )}
      </TableLayout>
      <Grid item container gap={2} justifyContent="flex-end">
        <CustomButton
          title="Process all"
          textColor={"#3E5EA9"}
          type={buttonType}
          borderRadius="3.9rem"
        />
        <CustomButton
          title="Refer all"
          textColor={"#D93832"}
          type={buttonTypes}
          borderRadius="3.9rem"
        />
      </Grid>
      <Grid item container justifyContent="flex-end">
        <CustomButton
          title="Continue"
          type={buttonTypess}
          borderRadius="3.9rem"
          width="15rem"
        />
      </Grid>
    </Grid>
  );
};

export default MedicationDetails;
