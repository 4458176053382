import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  editDelBtn: {
    "&.MuiButton-root": {
      backgroundColor: "#000000",
      color: theme.palette.common.grey,
      textTransform: "none",
      padding: 0,
      margin: 0,

      "&:hover": {
        background: "#fcfcfc",
      },

      "&:active": {
        background: "#fafafa",
      },

      "& .MuiButton-endIcon>*:nth-of-type(1)": {
        fontSize: "1.2rem",
      },

      "& .MuiButton-endIcon": {
        marginLeft: ".3rem",
        marginTop: "-.2rem",
      },
    },
  },

  tableCell: {
    "&.MuiTableCell-root": {
      color: "rgb(0 0 0)",
      fontWeight: 400,
      fontSize: "1.25rem",
      textAlign: "left",
      padding: "16px",
    },
  },

  btn: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    "&:disabled" : {
      cursor: "not-allowed"
    }
  },

  options: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    border: "1px solid #eee",
    position: "absolute",
    top: 50,
    right: 50,
    zIndex: 1,

    "&>button": {
      display: "flex",
      alignItems: "center",
      padding: "1rem 2rem",
      fontSize: "14px",
      fontWeight: 400,
      color: "#2D2F39",
      borderBottom: "1px solid #E5E5E5",
      textAlign: "left",
      whiteSpace: "nowrap",

      "&:hover": {
        color: "#ffffff",
      },
    },

    "&>:last-child": {
      borderBottom: "none",
      borderRadius: "0px 0px 8px 8px",
    },
  },

  editBtn: {
    "&:hover": {
      "&:disabled": {
        backgroundColor: "#96AAD9", 
      },
      backgroundColor: theme.palette.common.blue,
    },
  },

  delBtn: {
    "&:hover": {
      backgroundColor: theme.palette.common.danger,
    },
  },
}));
