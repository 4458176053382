import React from "react";
import displayPhoto from "assets/images/avatar.svg";
import PropTypes from "prop-types";
import { Grid, Avatar, Typography } from "@mui/material";
import { dateMoment, timeMoment } from "components/Utilities/Time";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  parentGridWrapper: {
    background: "#fff",
    borderRadius: "1rem",
    boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.1)",
    "&:not(:last-of-type)": {
      marginBottom: "5rem",
    },
  },
  gridsWrapper: {
    background: "#fff",
    borderRadius: "1rem",
    padding: "1rem",
    boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.2)",
  },

  badge: {
    "&.MuiChip-root": {
      fontSize: "1.3rem !important",
      //   height: "2.7rem",
      background: theme.palette.common.lightGreen,
      color: theme.palette.common.green,
      borderRadius: "1.5rem",
    },
  },

  cardGrid: {
    background: "#fff",
    borderRadius: "1rem",
    boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.2)",
  },

  infoBadge: {
    "&.MuiChip-root": {
      fontSize: "1.25rem",
      borderRadius: "1.5rem",
      color: theme.palette.common.green,
    },
  },

  link: {
    display: "flex",
    alignItems: "center",
    fontSize: "1.25rem",
    color: theme.palette.common.green,
    border: `1px solid ${theme.palette.common.lightGrey}`,
    padding: ".75rem",
    borderRadius: "1.5rem",
    textDecoration: "none",
  },

  linkIcon: {
    "&.MuiSvgIcon-root": {
      fontSize: "1.25rem",
      color: theme.palette.common.green,
      marginLeft: "1.2rem",
    },
  },

  buttonsGridWrapper: {
    marginTop: "5rem !important",
    height: "16.1rem",
  },

  title: {
    "&.MuiTypography-root": {
      color: theme.palette.common.grey,
    },
  },
}));
const Profile = (props) => {
  const classes = useStyles();

  const { referralId, createdAt, status, gender, patientData } = props;

  return (
    <Grid
      item
      container
      alignItems="center"
      display="grid"
      className={classes.cardGrid}
      gap={{ sm: 3, xs: 2 }}
      sx={{ paddingBlock: "6rem" }}
      gridTemplateColumns={{ sm: "repeat(4,1fr)", xs: "repeat(2,1fr)" }}
    >
      <Grid
        item
        container
        justifyContent="center"
        sx={{ gridRow: "1/4" }}
        alignItems="center"
      >
        <Avatar
          src={patientData ? patientData.image : displayPhoto}
          sx={{ minWidth: "150px", minHeight: "150px" }}
        />
      </Grid>
      <Grid item container direction="column" gap={1}>
        <Grid item>
          <Typography variant="body1" style={{ color: "#979797" }}>
            Patient Name
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="h4"
            style={{ fontWeight: "400", color: "#2C2C2C" }}
          >
            {"Ife Aberitise"}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container direction="column" gap={1}>
        <Grid item>
          <Typography variant="body1" style={{ color: "#979797" }}>
            Gender:
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="h4"
            style={{ fontWeight: "400", color: "#2C2C2C" }}
          >
            {gender ? gender : "Female"}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container direction="column" gap={1}>
        <Grid item>
          <Typography variant="body1" style={{ color: "#979797" }}>
            Status:
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="h4"
            style={{ fontWeight: "400", color: "#2C2C2C" }}
          >
            {status ? status : "Not Specified"}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container direction="column" gap={1}>
        <Grid item>
          <Typography variant="body1" style={{ color: "#979797" }}>
            Referral ID:
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="h4"
            style={{ fontWeight: "400", color: "#2C2C2C" }}
          >
            {referralId ? referralId.slice(0, 10) : "No Referral"}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container direction="column" gap={1}>
        <Grid item>
          <Typography variant="body1" style={{ color: "#979797" }}>
            Date
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="h4"
            style={{ fontWeight: "400", color: "#2C2C2C" }}
          >
            {createdAt ? `${dateMoment(Date())}` : "No Date "}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

Profile.propTypes = {
  fullName: PropTypes.string,
  type: PropTypes.string,
  displayPhoto: PropTypes.string,
  medicalTitle: PropTypes.string,
  statusId: PropTypes.number,
  specialization: PropTypes.string,
  status: PropTypes.string,
  chatPath: PropTypes.string,
  callPath: PropTypes.string,
  videoPath: PropTypes.string,
  setChatMediaActive: PropTypes.func,
};

export default Profile;
