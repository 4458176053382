import { SvgIcon } from "@mui/material";
import React from "react";

function TimesIcon(props) {
  return (
    <SvgIcon width="29" height="29" viewBox="0 0 29 29" {...props}>
      <rect x="2" y="2.14209" width="24" height="24" rx="4" fill="#FBEBEB" />
      <path
        d="M18.1195 11.0485L11.0485 18.1196C10.8069 18.3611 10.4062 18.3611 10.1646 18.1196C9.923 17.878 9.923 17.4773 10.1646 17.2357L17.2357 10.1646C17.4773 9.92301 17.878 9.92301 18.1195 10.1646C18.3611 10.4062 18.3611 10.8069 18.1195 11.0485Z"
        fill="#D93832"
      />
      <path
        d="M18.1195 18.1196C17.878 18.3612 17.4773 18.3612 17.2357 18.1196L10.1646 11.0485C9.923 10.8069 9.923 10.4062 10.1646 10.1646C10.4062 9.92303 10.8069 9.92303 11.0485 10.1646L18.1195 17.2357C18.3611 17.4773 18.3611 17.878 18.1195 18.1196Z"
        fill="#D93832"
      />
    </SvgIcon>
  );
}

export default TimesIcon;
