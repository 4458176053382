import { SvgIcon } from "@mui/material";
import React from "react";

function GtIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M0.910097 17.67C0.720097 17.67 0.530098 17.6 0.380098 17.45C0.0900977 17.16 0.0900977 16.68 0.380098 16.39L6.9001 9.86999C7.3801 9.38999 7.3801 8.60999 6.9001 8.12999L0.380098 1.60999C0.0900977 1.31999 0.0900977 0.839989 0.380098 0.549989C0.670098 0.259989 1.1501 0.259989 1.4401 0.549989L7.9601 7.06999C8.4701 7.57999 8.7601 8.26999 8.7601 8.99999C8.7601 9.72999 8.4801 10.42 7.9601 10.93L1.4401 17.45C1.2901 17.59 1.1001 17.67 0.910097 17.67Z"
        fill="#3E5EA9"
      />
    </SvgIcon>
  );
}

export default GtIcon;
