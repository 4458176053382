import React, { useState } from "react";
import { Grid, Typography, Divider, Chip } from "@mui/material";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import DeleteOrDisable from "components/modals/DeleteOrDisable";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import { AddOutlined } from "@mui/icons-material";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import { Profile } from "components/Utilities";
import FormikControl from "components/validation/FormikControl";
// import File2 from "components/validation/File2";
import { makeStyles } from "@mui/styles";
import { CustomButton, Modals } from "components/Utilities";
import { ReactComponent as DangerIcon } from "assets/images/Vector.svg";
import { useTheme } from "@mui/material/styles";
import TableLayout from "components/layouts/TableLayout";
// import { medicalHeadCells } from "components/Utilities/tableHeaders";
import { EmptyTable, EnhancedTable } from "components/layouts";
import { CustomSelect } from "components/validation/Select";
import GtIcon from "components/Icons/GtIcon";
import ScheduleTest from "./ScheduleTest";
import { Success } from "components/modals";
import ArrowUp from "components/Icons/ArrowUp";
import MarkIcon from "components/Icons/Mark";
import TimesIcon from "components/Icons/Times";
import EditIcon from "components/Icons/EditIcon";
import DeleteIcon from "components/Icons/deleteIcon";
import { Checkboxs } from "components/validation/Checkbox";
import { useHistory } from "react-router-dom";
// const useStyles = makeStyles((theme) => ({
//   buttonContainer: {
//     "&.MuiGrid-item": {
//       outline: "2px solid #fff",
//       padding: "2rem",
//       background: "#fff",
//       display: "flex",
//       gap: "2rem",
//     },
//   },
//   tableCell: {
//     "&.MuiTableCell-root": {
//       color: "rgb(0 0 0)",
//       fontWeight: 400,
//       fontSize: "1.25rem",
//       textAlign: "left",
//     },
//   },
//   typo: {
//     "&.MuiTypography-root": {
//       color: "#979797",
//       fontWeight: 500,
//       fontSize: "3.2rem",
//       textAlign: "center",
//       width: "100%",
//     },
//   },
// }));
const useStyles = makeStyles((theme) => ({
  divider: {
    "&.MuiDivider-root": {
      "&:(last-of-type)": {
        // display: "none",
        background: "red",
      },
    },
  },
  gridsWrapper: {
    background: "#fff",
    borderRadius: "1rem",
    minHeight: "15rem",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.2)",
  },

  cardsWrapper: {
    "&.MuiGrid-root > *": {
      flexWrap: "wrap",
    },
  },
  card: {
    "&.MuiGrid-root": {
      height: "100%",
      borderRadius: "1rem",
      flexDirection: "column",

      gap: "1rem",
    },
  },

  chipRoot: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    wordBreak: "break-all",
    borderRadius: "2.6rem",
    fontSize: "1.6rem",
    color: "#3EA584",
    border: "1px solid  #3EA584",
    padding: ".4rem",
    maxWidth: "max-content",
    minHeight: "2rem",
  },
  chipLabel: {
    overflowWrap: "break-word",
    whiteSpace: "nowrap",
    textOverflow: "clip",
  },
  testLabel: {
    background: "#E6E6E6",
    color: "#979797",
    borderColor: "transparent",
    fontSize: "1rem",
    padding: "2rem",
    borderRadius: "2.6rem",
  },

  cardContainer: {
    "&.MuiGrid-root": {
      display: "grid",
      gap: "2rem",

      "& > *": {
        flex: 1,
        boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.2)",
        minHeight: "14.9rem",
        background: "#fff",
      },
    },
  },
  buttonsGridWrapper: {
    marginTop: "5rem !important",
  },

  title: {
    "&.MuiTypography-root": {
      color: theme.palette.common.grey,
    },
  },
}));
const ScheduleTestInfo = (pendingProfile) => {
  const classes = useStyles();
  const theme = useTheme();
  const [finalArray, setFinalArray] = useState([]);
  const {
    createdAt,
    gender,
    sampleCollection,
    referralId,
    reason,
    testId,
    userLocation,
    status,
    doctor,
    tests,
    affiliation,
    testOption,
    doctorData,
    patientData,
    partnerData,
    // eslint-disable-next-line
  } = pendingProfile;

  const trasparentButton = {
    background: "#3E5EA9",
    hover: "#3E5EA9",
    active: "#3E5EA9",
    disabled: theme.palette.common.black,
  };

  const [value, setValue] = useState([]);
  const history = useHistory();
  const [menuState, setMenuState] = useState(true);
  const [menuStates, setMenuStates] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [finalSuccessModal, setFInalSuccessModal] = useState(false);
  const validationSchema = Yup.object({
    name: Yup.string("Enter your Name").trim().required("Name is required"),
    email: Yup.string("Enter your Email").trim().required("Email is required"),
    category: Yup.string("Select your Category"),
    image: Yup.string("Upload a single Image"),
  });
  const [scheduleState, setScheduleState] = useState("Pending");
  const bg = !menuState ? "#3E5EA9" : "#ECEFF6";
  const bg2 = menuState ? "#3E5EA9" : "#ECEFF6";
  const textColor = !menuState ? "#3E5EA9" : "#fff";
  const textColor2 = menuState ? "#3E5EA9" : "#fff";
  const buttonType = {
    background: bg,
    hover: bg,
    active: bg,
  };
  const buttonType2 = {
    background: "#3E5EA9",
    hover: "#3E5EA9",
    active: "#3E5EA9",
  };
  const buttonType5 = {
    background: "transparent",
    hover: "#3E5EA9",
    active: "#3E5EA9",
  };
  const buttonTypes = {
    background: bg2,
    hover: bg2,
    active: bg2,
  };
  const buttonTypes4 = {
    background: "#D2FCE9",
    hover: "#D2FCE9",
    active: "#D2FCE9",
  };
  const initialValues = {
    name: "",
    patient: "",
    diagnosis: "",
    test: "",
    check: "",
    remark: "",
  };
  const [individualState, setIndividualState] = useState(initialValues);
  const [logout, setLogout] = useState(false);
  const [Index, setIndex] = useState();
  const handleClose = () => setSuccessModal(false);
  const [viewState, setViewState] = useState(false);
  const handleDialogClose = () => {
    setFInalSuccessModal(false);
    setModal(false);
  };
  const [modal, setModal] = useState(false);
  const addModal = () => setModal(true);
  const [valuesArray, setValuesArray] = useState([]);
  const handleSubmit = (values, onSubmitProp) => {
    setValuesArray([...valuesArray, values]);
    onSubmitProp.resetForm();
  };
  const initialValue = {
    name: "",
    email: "",
    category: "",
    image: "",
    time: "",
    date: "",
    condition: "Good",
    gender: "Female",
    reason: "",
    note: "",
  };
  const [refferalModal, setReferralModal] = useState(false);
  return (
    <Grid container gap={4}>
      {scheduleState === "Pending" && !menuState ? null : scheduleState ===
          "Pending" && menuState ? (
        <Grid item gap={3} sx={{ mt: 4 }} container justifyContent="flex-end">
          <CustomButton
            title="Schedule Tests"
            textColor={textColor2}
            type={buttonType}
            borderRadius="6.6rem"
            width="20rem"
            onClick={() => setMenuStates(true)}
          />
          <CustomButton
            title="Refer"
            textColor={textColor}
            type={buttonTypes}
            borderRadius="6.6rem"
            width="10rem"
            onClick={() => setMenuState(false)}
          />
        </Grid>
      ) : (
        <Grid item container sx={{ mt: 4 }} justifyContent="flex-end">
          <CustomButton
            title="Test Scheduled"
            textColor="#66AE8D"
            type={buttonTypes4}
            borderRadius="1rem"
            width="20rem"
            disabled
            endIcon={<CheckCircleSharpIcon />}
            // onClick={() => setMenuState(false)}
          />
        </Grid>
      )}

      {menuState ? (
        <>
          <Profile
            createdAt={"createdAt"}
            gender={"Female"}
            referralId={"Xmudwwwdw5"}
            reason={"Diagnostics Test"}
            status={scheduleState}
            doctor={"doctor"}
            affiliation={"affiliation"}
            testOption={testOption}
            doctorData={"doctorData"}
            patientData={"patientData"}
            partnerData={"partnerData"}
            type="pending"
          />
          <Grid
            item
            container
            gridTemplateColumns={{ sm: "repeat(2,1fr)", xs: "repeat(1,1fr)" }}
            className={classes.cardContainer}
            sx={{ paddingTop: "2rem" }}
          >
            <Grid
              item
              xs={12}
              md={12}
              justifyContent={{ sm: "flex-start", xs: "center" }}
              alignItems={{ sm: "flex-start", xs: "center" }}
              padding={{ sm: "min(3rem,4vw)" }}
              container
              className={classes.card}
            >
              <Grid item>
                <Typography variant="body1">
                  {tests?.length > 1 ? "Tests" : "Test"}
                </Typography>
              </Grid>
              <Grid
                item
                justifyContent={{ sm: "flex-start", xs: "center" }}
                alignItems={{ sm: "flex-start", xs: "center" }}
                container
                gap={2}
                className={classes.cardsWrapper}
              >
                {tests?.length > 0 ? (
                  tests?.map((i, index) => {
                    return (
                      <Grid item key={index}>
                        <Chip
                          variant="outlined"
                          label={i?.name}
                          classes={{
                            root: classes.chipRoot,
                            label: classes.chipLabel,
                          }}
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item>
                    <Chip
                      variant="outlined"
                      label="No Test yet"
                      classes={{
                        root: classes.chipRoot,
                        label: classes.chipLabel,
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              justifyContent={{ sm: "flex-start", xs: "center" }}
              alignItems={{ sm: "flex-start", xs: "center" }}
              md={12}
              xs={12}
              container
              className={classes.card}
              padding={{ sm: "min(3rem,4vw)" }}
            >
              <Grid item>
                <Typography variant="body1">Doctor Name </Typography>
              </Grid>
              <Grid item>
                <Chip
                  variant="outlined"
                  label={testId ? testId : "No Value"}
                  classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              justifyContent={{ sm: "flex-start", xs: "center" }}
              alignItems={{ sm: "flex-start", xs: "center" }}
              container
              md={12}
              xs={12}
              className={classes.card}
              padding={{ sm: "min(3rem,4vw)" }}
            >
              <Grid item>
                <Typography variant="body1">Reason For Referral</Typography>
              </Grid>
              <Grid item>
                <Chip
                  variant="outlined"
                  label={reason ? reason : "NA"}
                  classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              justifyContent={{ sm: "flex-start", xs: "center" }}
              alignItems={{ sm: "flex-start", xs: "center" }}
              container
              md={12}
              xs={12}
              className={classes.card}
              padding={{ sm: "min(3rem,4vw)" }}
            >
              <Grid item>
                <Typography variant="body1">Test Result</Typography>
              </Grid>
              <Grid item>
                <Grid container flexWrap="nowrap" alignItems="center" gap={2}>
                  {finalArray.length > 0 && (
                    <Grid
                      item
                      flexWrap="nowrap"
                      alignItems="center"
                      container
                      gap={2}
                    >
                      {finalArray.map((i, index) => (
                        <Typography
                          sx={{
                            color: "#3EA584",
                            border: "1px solid  #3EA584",
                            padding: ".4rem 1rem",
                            borderRadius: "2.6rem",
                            fontSize: "1.6rem",
                          }}
                          key={index}
                        >
                          {i.diagnosis}
                        </Typography>
                      ))}
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    flexWrap="nowrap"
                    alignItems="center"
                    onClick={scheduleState !== "Pending" ? addModal : null}
                    gap={1}
                    sx={{
                      background:
                        scheduleState === "Pending" ? "#E6E6E6" : "#3E5EA9",
                      py: 1,
                      px: 2,
                      cursor: scheduleState === "Pending" ? "none" : "pointer",
                      color: scheduleState === "Pending" ? "#979797" : "#fff",
                      borderRadius: "2.6rem",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color="inherit"
                      sx={{ whiteSpace: "nowrap" }}
                      order={
                        scheduleState !== "Pending" && finalArray.length >= 1
                          ? 1
                          : 2
                      }
                    >
                      {scheduleState !== "Pending" && finalArray.length >= 1
                        ? "Add new"
                        : "Upload"}
                    </Typography>
                    {scheduleState !== "Pending" && finalArray.length >= 1 ? (
                      <AddOutlined />
                    ) : (
                      <ArrowUpward />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid item container>
          <Grid item xs={9} sx={{ margin: "auto", py: 4 }}>
            <Grid container>
              <Formik
                onSubmit={() => setReferralModal(true)}
                // validationSchema={validationSchema}
                validateOnChange={false}
                validateOnMount={false}
                initialValues={initialValue}
                enableReinitialize
              >
                {({ isSubmitting, dirty, isValid, setFieldValue }) => {
                  return (
                    <Grid item container direction="column" gap={4}>
                      <Form>
                        <Grid
                          item
                          container
                          direction="column"
                          gap="1.5rem"
                          sx={{
                            p: 4,
                            background: "#fff",
                            borderRadius: "1.6rem",
                          }}
                        >
                          <Typography variant="h2">
                            Patient Information
                          </Typography>
                          <Divider color={theme.palette.common.lighterGrey} />

                          <Grid item container gap={2} flexWrap="nowrap">
                            <Grid item md={6}>
                              <FormikControl
                                control="input"
                                name="name"
                                label="Heala ID "
                                placeholder="Enter Heala ID"
                              />
                            </Grid>
                            <Grid item md={6}>
                              <FormikControl
                                control="input"
                                name="FirstName"
                                label="first Name "
                                placeholder="Enter first Name"
                              />
                            </Grid>
                          </Grid>
                          <Grid item container gap={2} flexWrap="nowrap">
                            <Grid item md={6}>
                              <FormikControl
                                control="input"
                                name="lastName"
                                label="Last Name"
                                placeholder="Enter Last Name"
                              />
                            </Grid>
                            <Grid item md={6}>
                              <FormikControl
                                control="input"
                                name="phone"
                                label="Phone Number"
                                placeholder="Enter your Phone Number"
                              />
                            </Grid>
                          </Grid>
                          <Grid item container gap={2} flexWrap="nowrap">
                            <Grid item md={6}>
                              <FormikControl
                                control="input"
                                name="email"
                                label="Email Address"
                                placeholder="Enter your email Address"
                              />
                            </Grid>
                            <Grid item md={6}>
                              <FormikControl
                                control="select"
                                name="gender"
                                label="Gender"
                                options={[
                                  {
                                    key: "Female",
                                    value: "Female",
                                  },
                                ]}
                                placeholder="Enter your Phone Number"
                              />
                            </Grid>
                          </Grid>
                          <Typography variant="h2">Test Details</Typography>
                          <Divider color={theme.palette.common.lighterGrey} />
                          <Grid item container>
                            <FormikControl
                              control="textarea"
                              name="reason"
                              label="Reason for test"
                              minRows={2}
                              placeholder="Enter reason for test"
                            />
                          </Grid>
                          <Grid item container gap={2} flexWrap="nowrap">
                            <Grid item md={6}>
                              <FormikControl
                                control="time"
                                placeholder="28-04-2022"
                                name="date"
                                label="Date"
                              />
                            </Grid>
                            <Grid item md={6}>
                              <FormikControl
                                control="select"
                                name="condition"
                                label="Patient's Condition"
                                options={[{ key: "Good", value: "Good" }]}
                                placeholder="Enter your Phone Number"
                              />
                            </Grid>
                          </Grid>
                          <Grid item container>
                            <FormikControl
                              control="textarea"
                              name="note"
                              label="Add a Note to Heala "
                              placeholder="Add a Note"
                            />
                          </Grid>
                        </Grid>
                        <Grid item container sx={{ mt: 4 }}>
                          <CustomButton
                            variant="contained"
                            title="Refer"
                            type={trasparentButton}
                            borderRadius="6.6rem"
                            width="100%"
                            // className={classes.btn}
                            isSubmitting={isSubmitting}
                            // disabled={!(dirty || isValid)}
                          />
                        </Grid>
                      </Form>
                    </Grid>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Modals
        isOpen={menuStates}
        title="Schedule Test"
        rowSpacing={4}
        height="auto"
        handleClose={() => setMenuStates(false)}
      >
        <Formik
          initialValues={{
            time: "",
            date: "",
          }}
          onSubmit={{}}
          validateOnBlur={false}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnMount={false}
          enableReinitialize={true}
        >
          {({ isSubmitting, isValid, dirty }) => {
            return (
              <Form style={{ marginTop: "3rem" }}>
                <Grid container gap={3}>
                  <Grid item container>
                    <FormikControl
                      control="time"
                      placeholder="28-04-2022"
                      name="date"
                      label="Date"
                    />
                  </Grid>
                  <Grid item container>
                    <FormikControl
                      control="times"
                      name="time"
                      placeholder="9:34am"
                      label="Time"
                    />
                  </Grid>

                  <CustomButton
                    title="Schedule Test"
                    width="100%"
                    isSubmitting={isSubmitting}
                    disabled={!(dirty || isValid)}
                    type={buttonType2}
                    borderRadius="6.6rem"
                    onClick={() => {
                      setScheduleState("Scheduled");
                      setMenuStates(false);
                      setSuccessModal(true);
                    }}
                  />
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Modals>

      {/* Modal */}
      <Modals
        isOpen={modal}
        title={!viewState ? "Test Result" : "Test Result Summary"}
        rowSpacing={2}
        height="auto"
        handleClose={() => {
          setViewState(false);
          setModal(false);
        }}
      >
        {!viewState ? (
          <Formik
            initialValues={individualState}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            // validationSchema={validationSchema}
            validateOnChange={false}
            validateOnMount={false}
            enableReinitialize
          >
            {({ isSubmitting, isValid, setFieldValue, values, dirty }) => {
              return (
                <Form style={{ marginTop: "1rem" }}>
                  <Grid container gap={1.5}>
                    <Grid item container>
                      <FormikControl
                        control="input"
                        placeholder="Enter Hospital Name"
                        name="name"
                        label="Hospital's Name"
                      />
                    </Grid>
                    <Grid item container gap={2} flexWrap="nowrap">
                      <Grid item xs={6}>
                        <FormikControl
                          control="input"
                          placeholder="Enter Patient's Name"
                          name="patient"
                          label="Patient's Name"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormikControl
                          control="input"
                          placeholder="Enter Diagnosis Name"
                          name="diagnosis"
                          label="Diagnosis"
                        />
                      </Grid>
                    </Grid>
                    <Grid item container gap={2} flexWrap="nowrap">
                      <Grid item xs={6}>
                        <FormikControl
                          control="input"
                          placeholder="Enter Test Name"
                          name="test"
                          label="Test"
                        />
                      </Grid>
                      <Grid item xs={6} alignSelf="flex-end" justifyC="center">
                        {/* <Grid item container gap={2} alignItems="center"> */}

                        <Checkboxs
                          name="check"
                          type="radio"
                          value="Negative"
                          label="Negative"
                        />
                        <Checkboxs
                          name="check"
                          type="radio"
                          value="Positive"
                          label="Positive"
                        />

                        {/* </Grid> */}
                      </Grid>
                    </Grid>
                    <Grid item container flexWrap="nowrap">
                      <FormikControl
                        control="textarea"
                        placeholder="Enter Remark..."
                        name="remark"
                        minRows={2}
                        label="Remark"
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent="center"
                      sx={{
                        height: "4rem",
                        color: valuesArray.length > 0 ? "#3EA584" : "#979797",
                        border: "1px solid inherit",
                        background:
                          valuesArray.length > 0 ? "#F1FAF5" : "#FAFAFA",
                        borderRadius: ".8rem",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ color: "currentColor" }}>
                        {valuesArray.length} Result
                        {valuesArray.length > 1 ? "s" : ""} Added
                      </Typography>
                    </Grid>

                    <CustomButton
                      title="Add New Test Result"
                      endIcon={<AddOutlined />}
                      type={buttonType5}
                      textColor="#3E5EA9"
                      textColorOnHover="#fff"
                      width="100%"
                      complete={false}
                      isSubmitting={isSubmitting}
                      disabled={!(dirty || isValid)}
                      // onClick={() => handleClick(values, setFieldValue)}
                    />

                    <CustomButton
                      title="Save results and view Summary"
                      width="100%"
                      type={buttonType2}
                      borderRadius="6.6rem"
                      onClick={() => {
                        valuesArray.map((_, index) => {
                          if (Index === index) {
                            valuesArray[Index] = values;
                          }
                        });
                        setViewState(true);
                      }}
                    />
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <Grid item container>
            <Grid
              item
              container
              gap={2}
              sx={{
                background: "#FAFAFA",
                border: "1px solid #E6E6E6",
                borderRadius: ".8rem",
                padding: "2rem",
                marginTop: "3rem",
              }}
            >
              {valuesArray.length > 0 ? (
                valuesArray?.map((item, index) => {
                  const { patient, diagnosis, check } = item;
                  return (
                    <Grid item container flexDirection="column">
                      <Grid
                        item
                        container
                        alignItems="center"
                        key={index}
                        flexWrap="nowrap"
                      >
                        <Grid
                          item
                          flex={1}
                          gap={2}
                          alignItems="center"
                          flexWrap="nowrap"
                        >
                          <Typography
                            sx={{ color: "#97979", fontSize: "2rem" }}
                          >
                            {patient}
                          </Typography>
                          <Grid
                            item
                            container
                            gap={1}
                            alignItems="center"
                            flexWrap="nowrap"
                          >
                            <Typography
                              sx={{ color: " #6C6C6C", fontSize: "1.5rem" }}
                            >
                              {diagnosis}-{"  "}
                            </Typography>
                            <Grid
                              item
                              container
                              alignItems="center"
                              flexWrap="nowrap"
                            >
                              <Typography
                                sx={{
                                  color: "#2C2C2C",
                                  fontSize: "1.5rem",
                                  mr: 1,
                                }}
                              >
                                {check === "Positive" ? "Postive" : "Negative"}
                              </Typography>
                              {check === "Positive" ? (
                                <MarkIcon style={{ fontSize: "4rem" }} />
                              ) : (
                                <TimesIcon style={{ fontSize: "4rem" }} />
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <EditIcon
                            style={{
                              color: "#3E5EA9",
                              fill: "#3E5EA9",
                              cursor: "pointer",
                              fontSize: "2rem",
                              marginRight: "2rem",
                            }}
                            onClick={() => {
                              setViewState(false);
                              setModal(true);
                              setIndividualState(item);
                              setIndex(index);
                            }}
                          />
                          <DeleteIcon
                            style={{
                              color: "#D93832",
                              fontSize: "2rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setLogout(true);
                              setIndex(index);
                            }}
                          />
                        </Grid>
                      </Grid>
                      {index !== valuesArray.length - 1 && (
                        <Divider className={classes.divider} sx={{ mt: 2 }} />
                      )}
                    </Grid>
                  );
                })
              ) : (
                <Typography variant="h5">No Data</Typography>
              )}
            </Grid>
            <Grid item container sx={{ my: 2 }}>
              <CustomButton
                title="Add New Test Result"
                endIcon={<AddOutlined />}
                type={buttonType5}
                textColor="#3E5EA9"
                textColorOnHover="#fff"
                width="100%"
                complete={false}
                onClick={() => setViewState(false)}
              />
            </Grid>
            <CustomButton
              title="Upload results and to Patient profile"
              width="100%"
              type={buttonType2}
              borderRadius="6.6rem"
              onClick={() => {
                setFInalSuccessModal(true);
                setFinalArray(valuesArray);
              }}
            />
          </Grid>
        )}
      </Modals>
      <Success
        title="Schedule Successful"
        confirmationMsg="Patient’s test has been successfully booked"
        btnValue="Great!"
        open={successModal}
        handleDialogClose={handleClose}
      />
      <Success
        title="Reffered Successful"
        confirmationMsg="Patient’s test has been successfully refered. Heala will receive your request and process"
        btnValue="Great!"
        open={refferalModal}
        handleDialogClose={() => {
          setReferralModal(false);
          history.push("/tests");
        }}
      />
      <Success
        title="Result Uploaded Successfully"
        confirmationMsg="Patient’s test has been successfully added"
        btnValue="Great!"
        open={finalSuccessModal}
        handleDialogClose={handleDialogClose}
      />
      <DeleteOrDisable
        open={logout}
        setOpen={setLogout}
        title="Delete this test"
        confirmationMsg="delete this tweet? This action can not be undone"
        btnValue="Delete"
        // type="logout"
        onConfirm={() => {
          valuesArray.splice(Index, 1);

          setLogout(false);
        }}
      />
    </Grid>
  );
};

export default ScheduleTestInfo;
