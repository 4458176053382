import React from "react";
import { Field, ErrorMessage, useField, useFormikContext } from "formik";
import { TextError } from "components/Utilities";
import {
  FormLabel,
  FormControlLabel,
  Box,
  Grid,
  Checkbox,
  Typography,
  Radio,
} from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  checkboxContainer: {
    "&.MuiBox-root": {
      padding: "2rem 0",
      border: "1px solid #E0E0E0",
      borderRadius: ".4rem",
      // "&:active": {
      //   border: "2px solid black",
      // },
    },
  },
  label: {
    ...theme.typography.FormLabel,
  },
  checkbox: {
    height: "2rem",
    width: "2rem",
    "&:checked": {
      color: "green !important",
    },
  },
}));
export const Checkboxs = ({ label, ...props }) => {
  const [field] = useField(props);
  const { setFieldValue } = useFormikContext();

  return (
    <FormControlLabel
      {...field}
      control={
        <Radio
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: 28,
            },
            "&.Mui-checked": {
              color: field.value === "Negative" ? "#f00" : "#0f0",
            },
          }}
          color="primary"
        />
      }
      label={label}
    />
  );
};

Checkboxs.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
};

const CustomCheckbox = ({
  // children,
  label,
  // name,
  // checkboxTitle,
  // onChange,
  // value,
  // color,
  ...props
}) => {
  return (
    <Field
      // id={name}
      // color={color}
      // name={name}
      label={label}
      as={Checkboxs}
      {...props}
    />
  );
};
CustomCheckbox.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  checkboxTitle: PropTypes.string,
  onChange: PropTypes.func,
};
export default CustomCheckbox;
