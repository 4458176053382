import React, { useState, useEffect, Suspense, useMemo } from "react";
import "./App.css";
import jwtDecode from "jwt-decode";
import * as Sentry from "@sentry/react";
import { Login } from "components/pages";
import { useSelector } from "react-redux";
import { isAppLoading } from "helpers/func";
import { useApptype } from "components/hooks";
import { Loader } from "components/Utilities";
import Routes from "components/routes/Routes";
import Hmo from "components/routes/HmoRoutes";
import { muiTheme } from "components/muiTheme";
import { setAccessToken } from "./accessToken";
import Private from "components/routes/Private";
import OTP from "components/pages/ChangePassword";
import Hospital from "components/routes/Hospital";
import { ThemeProvider } from "@mui/material/styles";
import { getPartner } from "components/graphQL/useQuery";
import { useActions } from "components/hooks/useActions";
import { LOGOUT_USER } from "components/graphQL/Mutation";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AppContainer } from "components/layouts/AppContainer";
import { AppTypeProvider } from "store/contexts/AppTypeContext";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const getAppsObj = () => {
  return {
    diagnostics: <Private />,
    pharmacy: <Routes />,
    hospital: <Hospital />,
    hmo: <Hmo />,
  };
};

const PreApp = ({ window }) => {
  const { changeAppType } = useApptype();
  const [state, setState] = useState(true);
  const id = localStorage.getItem("AppId");
  const { userDetail, logout } = useActions();
  const [logout_user] = useMutation(LOGOUT_USER);
  const { isAuthenticated, role } = useSelector((state) => state.auth);
  const [pharmacy, { data, loading }] = useLazyQuery(getPartner, {
    variables: { id },
  });
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const isLoading = useMemo(
    () => isAppLoading(isAuthenticated, role, state, loading),
    [isAuthenticated, role, state, loading]
  );

  useEffect(() => {
    changeAppType(role);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  useEffect(() => {
    const token = localStorage.getItem("App_Token");
    const d = async () => {
      if (token) {
        const { exp } = jwtDecode(token);
        const time = Date.now() >= exp * 1000;
        if (token && time) {
          logout();
          logout_user();
        }
        if (token && isAuthenticated && !time && state) {
          setState(false);
          setAccessToken(token);
        } else if (token && isAuthenticated && !time && !state) {
          setAccessToken(token);
          try {
            await pharmacy();
          } catch (err) {
            console.error(err);
          }

          if (data) {
            const partnerData = data?.getPartner;
            userDetail({
              data: partnerData?.category,
            });
            localStorage.setItem("partnerData", JSON.stringify(partnerData));
          }
          setState(false);
        }
      }
    };
    d();
    // eslint-disable-next-line
  }, [data, pharmacy, state, isAuthenticated]);

  return (
    <>
      <Router>
        <div className="container">
          {/* SHOW LOGIN WHEN NO AUTH */}
          {!isAuthenticated && (
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route path="/otp" render={(props) => <OTP {...props} />} />
                <Route
                  path={["/login", "/"]}
                  render={(props) => <Login {...props} />}
                />
              </Switch>
            </Suspense>
          )}

          {/* SHOW LOADING WHEN APP IS STILL LOADING */}
          {isLoading && <Loader color="success" />}

          {/* SHOW CORRECT APP ACCORDING TO USER ROLE */}
          {isAuthenticated && (
            <AppContainer container={container}>
            {getAppsObj()[role]}
          </AppContainer>
          )}
        </div>
      </Router>
    </>
  );
};

const App = () => {

  return (
    <ThemeProvider theme={muiTheme}>
      <AppTypeProvider>
        <PreApp />
      </AppTypeProvider>
    </ThemeProvider>
  );
};

export default Sentry.withProfiler(App);
