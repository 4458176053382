import { SvgIcon } from "@mui/material";
import React from "react";

function PlansIcon(props) {
  return (
    <SvgIcon viewBox="0 0 22 22" {...props}>
      <path d="M13.1541 2.82715H8.84578C7.89245 2.82715 7.11328 3.59715 7.11328 4.55048V5.41215C7.11328 6.36548 7.88328 7.13548 8.83661 7.13548H13.1541C14.1074 7.13548 14.8774 6.36548 14.8774 5.41215V4.55048C14.8866 3.59715 14.1074 2.82715 13.1541 2.82715Z" />
      <path d="M15.8034 5.41233C15.8034 6.86983 14.6117 8.0615 13.1542 8.0615H8.84587C7.38837 8.0615 6.1967 6.86983 6.1967 5.41233C6.1967 4.899 5.6467 4.57817 5.18837 4.8165C3.89587 5.504 3.01587 6.86983 3.01587 8.43733V17.0632C3.01587 19.3182 4.85837 21.1607 7.11337 21.1607H14.8867C17.1417 21.1607 18.9842 19.3182 18.9842 17.0632V8.43733C18.9842 6.86983 18.1042 5.504 16.8117 4.8165C16.3534 4.57817 15.8034 4.899 15.8034 5.41233ZM11.3484 16.5315H7.33337C6.95754 16.5315 6.64587 16.2198 6.64587 15.844C6.64587 15.4682 6.95754 15.1565 7.33337 15.1565H11.3484C11.7242 15.1565 12.0359 15.4682 12.0359 15.844C12.0359 16.2198 11.7242 16.5315 11.3484 16.5315ZM13.75 12.8648H7.33337C6.95754 12.8648 6.64587 12.5532 6.64587 12.1773C6.64587 11.8015 6.95754 11.4898 7.33337 11.4898H13.75C14.1259 11.4898 14.4375 11.8015 14.4375 12.1773C14.4375 12.5532 14.1259 12.8648 13.75 12.8648Z" />
    </SvgIcon>
  );
}

export default PlansIcon;
