import React, { useState, useEffect } from "react";
import TableLayout from "components/layouts/TableLayout";
import { NoData, EmptyTable } from "components/layouts";
import { TableCell, TableRow, Grid } from "@mui/material";
// import { Loader, PatientFilters, CompoundSearch } from "components/Utilities";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { EnhancedTable } from "components/layouts";
import { ref } from "components/Utilities/tableHeaders";
import { Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import { CustomButton, Search } from "components/Utilities";
import { useLazyQuery } from "@apollo/client";

import {
  changeHospitalTableLimit,
  handleHospitalPageChange,
  getSearchPlaceholder,
} from "helpers/filterHelperFunctions";
import {
  getPatients,
  getPatientsByPlan,
  getPatientsByStatus,
} from "components/graphQL/useQuery";
import RefferalFilter from "components/Utilities/RefferalFilter";
import { FormikControl } from "components/validation";
import { CustomSelect } from "components/validation/Select";

const useStyles = makeStyles(() => ({
  searchFilterContainer: {
    "&.MuiGrid-root": {
      justifyContent: "space-between",
    },
  },
  button: {
    "&.MuiButton-root": {
      background: "#fff",
      color: "#757886",
      textTransform: "none",
      borderRadius: "2rem",
      display: "flex",
      alignItems: "center",
      padding: "1rem",
      maxWidth: "10rem",
      whiteSpace: "nowrap",

      "&:hover": {
        background: "#fcfcfc",
      },

      "&:active": {
        background: "#fafafa",
      },

      "& .MuiButton-endIcon>*:nth-of-type(1)": {
        fontSize: "1.2rem",
      },

      "& .MuiButton-endIcon": {
        marginLeft: ".3rem",
        marginTop: "-.2rem",
      },
    },
  },

  tableCell: {
    "&.MuiTableCell-root": {
      fontSize: "1.25rem",
      textAlign: "left",
    },
  },

  badge: {
    "&.MuiChip-root": {
      fontSize: "1.25rem !important",
      height: "2.7rem",

      borderRadius: "1.3rem",
    },
  },
  searchFilterBtn: {
    "&.MuiButton-root": {
      fontSize: "1.5rem",
      textTransform: "none",
      height: "5rem",
      borderRadius: 10,
      boxShadow: "0px 0px 4px -1px rgba(71,64,71,0.63)",
      background: "#2D2F39",
      width: "100%",
    },
  },
}));

const Medication = () => {
  const classes = useStyles();
  const theme = useTheme();
  //   const [fetchPatient, { loading, refetch, error, variables }] =
  //     useLazyQuery(getPatients);
  //   const [
  //     fetchPatientByStatus,
  //     {
  //       loading: byStatusLoading,
  //       variables: byStatusVaribles,
  //       refetch: byStatusRefetch,
  //     },
  //   ] = useLazyQuery(getPatientsByStatus);
  //   const [
  //     fetchPatientByPlan,
  //     {
  //       loading: byPlanLoading,
  //       variables: byPlanVaribles,
  //       refetch: byPlanRefetch,
  //     },
  //   ] = useLazyQuery(getPatientsByPlan);
  //   const id = localStorage.getItem("partnerProviderId");

  //   const setTableData = async (response, errMsg) => {
  //     response
  //       .then(({ data }) => {
  //         setPageInfo(data?.profiles?.pageInfo || []);
  //         setProfiles(data?.profiles?.data || defaultPageInfo);
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   };

  //   useEffect(() => {
  //     fetchPatient({
  //       variables: {
  //         first: pageInfo.limit,
  //       },
  //     }).then(({ data }) => {
  //       if (data) {
  //         setPageInfo(data?.profiles?.pageInfo || []);
  //         setProfiles(data?.profiles?.data || defaultPageInfo);
  //       }
  //     });
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  //   useEffect(() => {
  //     fetchPatient({
  //       variables: {
  //         first: 10,
  //         providerId: id,
  //       },
  //     });
  //   }, [fetchPatient, id]);

  const [profiles, setProfiles] = useState(
    Array(5).fill({
      date: "18-01-2012",
      orderId: "sdfgdsfhfgh",
      doctor: "Prince Williams",
      patient: "Heala Doctor",
      amount: "N200:00",
      test: 2,
    })
  );
  const buttonType = {
    background: "#ECEFF6",
    hover: "#ECEFF6",
    active: "#ECEFF6",
  };
  const buttonTypes = {
    background: "#FBEBEB",
    hover: "#FBEBEB",
    active: "#FBEBEB",
  };

  const [pageInfo, setPageInfo] = useState({
    page: 0,
    totalPages: 1,
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    totalDocs: 0,
  });
  const history = useHistory();
  const buttonTypesss = {
    background: "#fff",
    hover: "#fff",
    active: "#fff",
  };
  //   if (loading) return <Loader />;
  //   if (error) return <NoData error={error} />;
  return (
    <Grid item flex={1} container direction="column" rowGap={2}>
      <TableLayout noSearch={true}>
        <Grid
          item
          container
          justifyContent="space-between"
          sx={{ mb: 4 }}
          alignItems="center"
        >
          <Grid item xs={2}>
            <CustomSelect
              variant="small"
              options={[
                {
                  key: "Pending",
                  value: "Pending",
                },
              ]}
              value="Pending"
            />
          </Grid>
          <Grid item xs={8}>
            <Search
              placeholder="Search by Test name"
              width="100%"
              borderRadius="4rem"
              height="4.5rem"
            />
          </Grid>

          <CustomButton
            title="Search"
            type={buttonTypesss}
            borderRadius="6.6rem"
            border="2px solid #3E5EA9"
            textColor="#3E5EA9"
            boxShadow="none"
            width="15rem"
          />
          {/* </Grid> */}
        </Grid>
        {profiles.length > 0 ? (
          /* ================= PATIENTS TABLE ================= */
          <Grid
            container
            item
            direction="column"
            overflow="hidden"
            maxWidth={{ md: "100%", sm: "100%", xs: "100%" }}
          >
            <EnhancedTable
              headCells={ref}
              rows={profiles}
              paginationLabel="Patients per page"
              hasCheckbox={false}
              //   changeLimit={async (e) => {
              //     const res = changeHospitalTableLimit(fetchPatient, {
              //       first: e,
              //       providerId: id,
              //     });
              //     await setTableData(res, "Failed to change table limit.");
              //   }}
              dataPageInfo={pageInfo}
              //   handlePagination={async (page) => {
              //     const res = handleHospitalPageChange(
              //       fetchPatient,
              //       page,
              //       pageInfo,
              //       id
              //     );
              //     await setTableData(res, "Failed to change page.");
              //   }}
            >
              {profiles.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                // const {
                //   dociId,
                //   firstName,
                //   lastName,
                //   plan,
                //   provider,
                //   //image,
                //   consultations,
                //   _id,
                //   status,
                // } = row;
                const { date, orderId, doctor, patient, amount, test } = row;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push(`medical/${index}`)}
                  >
                    <TableCell
                      id={labelId}
                      scope="row"
                      align="left"
                      className={classes.tableCell}
                      style={{
                        color: theme.palette.common.grey,
                        textAlign: "left",
                      }}
                    >
                      {date}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {/* <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          test="test-value"
                          style={{ fontSize: "1.25rem" }}
                        >{`${firstName} ${lastName}`}</span>
                      </div> */}
                      {orderId}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {doctor}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCell}
                      data-testid="test-value"
                    >
                      {patient}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {amount}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {test}
                    </TableCell>
                  </TableRow>
                );
              })}
            </EnhancedTable>
          </Grid>
        ) : (
          <EmptyTable headCells={ref} paginationLabel="Test per page" />
        )}
      </TableLayout>
    </Grid>
  );
};

export default Medication;
