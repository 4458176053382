import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Grid, Chip } from "@mui/material";
import { Profile } from "components/Utilities";
import ArrowUp from "components/Icons/ArrowUp";

const useStyles = makeStyles((theme) => ({
  gridsWrapper: {
    background: "#fff",
    borderRadius: "1rem",
    minHeight: "15rem",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.2)",
  },

  cardsWrapper: {
    "&.MuiGrid-root > *": {
      flexWrap: "wrap",
    },
  },
  card: {
    "&.MuiGrid-root": {
      height: "100%",
      borderRadius: "1rem",
      flexDirection: "column",

      gap: "1rem",
    },
  },

  chipRoot: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    wordBreak: "break-all",
    fontSize: "clamp(1.2rem, 3vw, 1.3rem) ",
    color: theme.palette.common.green,
    border: "1px solid #bdbdbd",
    padding: ".4rem",
    maxWidth: "max-content",
    minHeight: "2rem",
  },
  chipLabel: {
    overflowWrap: "break-word",
    whiteSpace: "nowrap",
    textOverflow: "clip",
  },
  testLabel: {
    background: "#E6E6E6",
    color: "#979797",
    borderColor: "transparent",
    fontSize: "1rem",
    padding: "2rem",
    borderRadius: "2.6rem",
  },

  cardContainer: {
    "&.MuiGrid-root": {
      display: "grid",
      gap: "2rem",

      "& > *": {
        flex: 1,
        boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.2)",
        minHeight: "14.9rem",
        background: "#fff",
      },
    },
  },
  buttonsGridWrapper: {
    marginTop: "5rem !important",
  },

  title: {
    "&.MuiTypography-root": {
      color: theme.palette.common.grey,
    },
  },
}));
const ScheduleTest = (pendingProfile) => {
  const classes = useStyles();

  const {
    createdAt,
    gender,
    sampleCollection,
    referralId,
    reason,
    testId,
    userLocation,
    status,
    doctor,
    tests,
    affiliation,
    testOption,
    doctorData,
    patientData,
    partnerData,
    // eslint-disable-next-line
  } = pendingProfile;

  return (
    <>
      <Grid container direction="column" style={{ paddingBottom: "5rem" }}>
        <Profile
          createdAt={"createdAt"}
          gender={"Female"}
          referralId={"Xmudwwwdw5"}
          reason={"Diagnostics Test"}
          status={"Pending"}
          doctor={"doctor"}
          affiliation={"affiliation"}
          testOption={testOption}
          doctorData={"doctorData"}
          patientData={"patientData"}
          partnerData={"partnerData"}
          type="pending"
        />
        <Grid
          item
          container
          gridTemplateColumns={{ sm: "repeat(2,1fr)", xs: "repeat(1,1fr)" }}
          className={classes.cardContainer}
          sx={{ paddingTop: "5rem" }}
        >
          <Grid
            item
            xs={12}
            md={12}
            justifyContent={{ sm: "flex-start", xs: "center" }}
            alignItems={{ sm: "flex-start", xs: "center" }}
            padding={{ sm: "min(3rem,4vw)" }}
            container
            className={classes.card}
          >
            <Grid item>
              <Typography variant="body1">
                {tests?.length > 1 ? "Tests" : "Test"}
              </Typography>
            </Grid>
            <Grid
              item
              justifyContent={{ sm: "flex-start", xs: "center" }}
              alignItems={{ sm: "flex-start", xs: "center" }}
              container
              gap={2}
              className={classes.cardsWrapper}
            >
              {tests?.length > 0 ? (
                tests?.map((i, index) => {
                  return (
                    <Grid item key={index}>
                      <Chip
                        variant="outlined"
                        label={i?.name}
                        classes={{
                          root: classes.chipRoot,
                          label: classes.chipLabel,
                        }}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Grid item>
                  <Chip
                    variant="outlined"
                    label="No Test yet"
                    classes={{
                      root: classes.chipRoot,
                      label: classes.chipLabel,
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            justifyContent={{ sm: "flex-start", xs: "center" }}
            alignItems={{ sm: "flex-start", xs: "center" }}
            md={12}
            xs={12}
            container
            className={classes.card}
            padding={{ sm: "min(3rem,4vw)" }}
          >
            <Grid item>
              <Typography variant="body1">Doctor Name </Typography>
            </Grid>
            <Grid item>
              <Chip
                variant="outlined"
                label={testId ? testId : "No Value"}
                classes={{ root: classes.chipRoot, label: classes.chipLabel }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            justifyContent={{ sm: "flex-start", xs: "center" }}
            alignItems={{ sm: "flex-start", xs: "center" }}
            container
            md={12}
            xs={12}
            className={classes.card}
            padding={{ sm: "min(3rem,4vw)" }}
          >
            <Grid item>
              <Typography variant="body1">Reason For Referral</Typography>
            </Grid>
            <Grid item>
              <Chip
                variant="outlined"
                label={reason ? reason : "NA"}
                classes={{ root: classes.chipRoot, label: classes.chipLabel }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            justifyContent={{ sm: "flex-start", xs: "center" }}
            alignItems={{ sm: "flex-start", xs: "center" }}
            container
            md={12}
            xs={12}
            className={classes.card}
            padding={{ sm: "min(3rem,4vw)" }}
          >
            <Grid item>
              <Typography variant="body1">Test Result</Typography>
            </Grid>
            <Grid item>
              <Chip
                variant="outlined"
                // label={reason ? reason : "NA"}
                label={"Upload"} //<ArrowUp
                className={classes.testLabel}
                // classes={{ root: classes.chipRoot, label: classes.chipLabel }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ScheduleTest;
