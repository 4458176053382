import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { Loader } from "components/Utilities";
import { useLazyQuery } from "@apollo/client";
import { defaultPageInfo } from "helpers/mockData";
import displayPhoto from "assets/images/avatar.svg";
import TableLayout from "components/layouts/TableLayout";
import { getAppointments } from "components/graphQL/useQuery";
import { Grid, TableRow, TableCell, Avatar } from "@mui/material";
import { NoData, EmptyTable, EnhancedTable } from "components/layouts";
import { patientAppointmentHeadCells } from "components/Utilities/tableHeaders";
import {
  changeTableLimit,
  handlePageChange,
} from "helpers/filterHelperFunctions";
import { formatDate } from "helpers/func";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    "&.css-1jilxo7-MuiTableCell-root": {
      fontSize: "1.25rem",
    },
  },
  btn: {
    "&.MuiButton-root": {
      ...theme.typography.btn,
      width: "100%",
    },
  },

  tableBtn: {
    "&.MuiButton-root": {
      ...theme.typography.btn,
      height: "3rem",
      fontSize: "1.25rem",
      borderRadius: "2rem",
      boxShadow: "none",

      "&:hover": {
        "& .MuiButton-endIcon>*:nth-of-type(1)": {
          color: "#fff",
        },
      },

      "&:active": {
        boxShadow: "none",
      },

      "& .MuiButton-endIcon>*:nth-of-type(1)": {
        fontSize: "1.5rem",
      },
    },
  },

  redBtn: {
    "&.MuiButton-root": {
      background: theme.palette.common.lightRed,
      color: theme.palette.common.red,

      "&:hover": {
        background: theme.palette.error.light,
        color: "#fff",
      },
    },
  },

  greenBtn: {
    "&.MuiButton-root": {
      background: theme.palette.common.lightGreen,
      color: theme.palette.common.green,

      "&:hover": {
        background: theme.palette.success.light,
        color: "#fff",
      },
    },
  },
}));

const PatientAppointment = () => {
  const classes = useStyles();
  const { patientId } = useParams();
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [getPatientAppointments, { loading, data, error, variables }] =
    useLazyQuery(getAppointments, {variables: {patientId}});

  const patientAppointments = data?.getNextConsultations?.data || [];

  useEffect(() => {
    getPatientAppointments();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPageInfo(data?.getNextConsultations?.pageInfo);
  }, [data]);

  return (
    <>
      <Grid
        container
        direction="column"
        gap={2}
        flexWrap="nowrap"
        height="100%"
      >
        <TableLayout>
          {error ? (
            <NoData error={error} />
          ) : loading ? (
            <Loader />
          ) : patientAppointments.length > 0 ? (
            <Grid item container height="100%" direction="column">
              <EnhancedTable
                headCells={patientAppointmentHeadCells}
                rows={patientAppointments}
                paginationLabel="Appointments per page"
                hasCheckbox={false}
                changeLimit={async (e) => {
                  changeTableLimit(getPatientAppointments, {
                    ...variables,
                    first: e,
                  });
                }}
                dataPageInfo={pageInfo}
                handlePagination={async (page) => {
                  handlePageChange(getPatientAppointments, page, pageInfo, {
                    ...variables,
                  });
                }}
              >
                {patientAppointments.map((appointment) => {
                  const { _id, doctor, time, contactMedium } = appointment;
                  const doctorName = doctor ? `${doctor?.firstName} ${doctor?.lastName}` : "No Doctor";
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={_id}>
                      <TableCell
                        align="left"
                        className={classes.tableCell}
                        style={{ maxWidth: "20rem" }}
                      >
                        <div
                          style={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span style={{ marginRight: "1rem" }}>
                            <Avatar
                              alt={`Display Photo of ${doctorName}`}
                              src={
                                doctor?.picture
                                  ? doctor?.picture
                                  : displayPhoto
                              }
                              sx={{ width: 24, height: 24 }}
                            />
                          </span>
                          <span style={{ fontSize: "1.25rem" }}>
                            {doctorName}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        {formatDate(time, "PPP")}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        {formatDate(time, "p")}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCell}
                      >
                         {contactMedium}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </EnhancedTable>
            </Grid>
          ) : (
            <EmptyTable
              headCells={patientAppointmentHeadCells}
              paginationLabel="Appointments per page"
            />
          )}
        </TableLayout>
      </Grid>
    </>
  );
};

export default PatientAppointment;
