import React from "react";
import t from "prop-types";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import { Formik, Form } from "formik";
import { useAlert } from "components/hooks";
import { useTheme } from "@mui/material/styles";
import { CustomButton } from "components/Utilities";
import { FormikControl } from "components/validation";
import { removeEmptyStringValues } from "helpers/func";
import { useMutation } from "@apollo/client";
import { addCompany, updateCompany } from "components/graphQL/Mutation";

const companyFormValidationSchema = Yup.object({
  name: Yup.string("Enter Company name")
    .trim()
    .required("Company name is required"),
  // email: Yup.string("Enter email address").email("Enter a valid email").trim(),
  // phone: Yup.number("Enter Company phone number")
  //   .typeError(" Enter a valid phone number")
  //   .min(11, "min value is  11 digits"),
  // address: Yup.string("Enter Company address").trim(),
  logo: Yup.string("Upload company logo"),
});

const AddEditCompanyForm = ({ type, initialValues, onSuccess }) => {
  const theme = useTheme();
  const companyId = initialValues?._id;
  const { displayAlert, getErrorMsg } = useAlert();
  const [editCompany] = useMutation(updateCompany);
  const [createNewCompany] = useMutation(addCompany);
  const providerId = localStorage.getItem("partnerProviderId");

  const buttonType = {
    background: theme.palette.common.black,
    hover: theme.palette.primary.main,
    active: theme.palette.primary.dark,
  };

  const onCreateCompany = async (values) => {
    try {
      if(!providerId) throw Error("No provider ID found!");
      const variables = removeEmptyStringValues({providerId, ...values});
      const res = await createNewCompany({variables})
      const companyName = res?.data?.createCompany?.company?.name || "company";
      displayAlert("success", `${companyName} added!`);
      onSuccess();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      const errorMsg = getErrorMsg(error);
      displayAlert("error", errorMsg);
    }
  };

  const onUpdateCompany = async (values) => {
    try {
      if(!companyId) throw Error("company ID not found!");
      if(!providerId) throw Error("No provider ID found!");

      const variables = removeEmptyStringValues({ companyId, providerId, ...values});
      const res = await editCompany({variables})
      console.log(res);
      const companyName = res?.data?.updateCompany?.company?.name || "company";
      displayAlert("success", `${companyName} Updated!`);
      onSuccess();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      const errorMsg = getErrorMsg(error);
      displayAlert("error", errorMsg);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) =>
        type === "add" ? onCreateCompany(values) : onUpdateCompany(values)
      }
      validationSchema={companyFormValidationSchema}
      validateOnChange={true}
      validateOnMount={false}
      validateOnBlur={true}
    >
      {({ isSubmitting, setFieldValue }) => {
        return (
          <Form style={{ marginTop: "1rem" }}>
            <Grid container direction="column" gap={2}>

              <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="input"
                  label="Company Name"
                  id="name"
                  name="name"
                  placeholder="Enter Company name"
                />
              </Grid>

              {/* <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="input"
                  type="email"
                  label="Email"
                  id="email"
                  name="email"
                  placeholder="Enter email address"
                />
              </Grid>

              <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="input"
                  label="Phone number"
                  id="phone_number"
                  name="phone"
                  placeholder="Enter phone number"
                />
              </Grid>

              <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="input"
                  label="Address"
                  id="address"
                  name="address"
                  placeholder="Address"
                />
              </Grid> */}

              <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="file"
                  label="Upload your logo"
                  id="logo"
                  name="logo"
                  setFieldValue={setFieldValue}
                />
              </Grid>

              <Grid item container sx={{ mt: 4 }}>
                <CustomButton
                  title={type === "edit" ? "Update Company" : "Add Company"}
                  width="100%"
                  type={buttonType}
                  isSubmitting={isSubmitting}
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

AddEditCompanyForm.propTypes = {
  onSuccess: t.func.isRequired,
  initialValues: t.object.isRequired,
  type: t.oneOf(["add", "edit"]).isRequired,
};

export default AddEditCompanyForm;
