import React, { useCallback, useEffect, useState } from "react";
import t from "prop-types";
import * as Yup from "yup";
import { debounce } from "lodash";
import { Formik, Form } from "formik";
import { isFile } from "helpers/func";
import DragAndDrop from "./DragAndDrop";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import { CustomButton } from "components/Utilities";
import { FormikControl } from "components/validation";
import { Alert, Grid, TextField, Typography } from "@mui/material";
import { AutoComplete } from "components/validation/AutoComplete";
import { getCompanies, getPlans } from "components/graphQL/useQuery";

//import { uploadEnrolleeFileValidationSchema } from "helpers/validationSchemas";

export const uploadEnrolleeFileValidationSchema = Yup.object({
  planId: Yup.string("Please select a plan").required("Plan is required."),
  // replace: Yup.boolean(),
  companyId: Yup.string("Select a company"),
  file: Yup.mixed()
    .required("Select a .CSV file to proceed.")
    .test("type", "Only .CSV and .JSON files are supported", (value) => {
      const isCSVFile = isFile(value, "csv");
      const isJSONFile = isFile(value, "json");
      const passed = isCSVFile || isJSONFile;
      return passed;
    }),
});

export const UploadEnrolleeListForm = ({ onSubmit, templateError=false }) => {
  const theme = useTheme();
  const { companyId } = useParams();
  const [fetchPlans] = useLazyQuery(getPlans);
  // const [replace, setReplace] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [planOptions, setPlanOptions] = useState([]);
  const providerId = localStorage.getItem("partnerProviderId");
  const [fetchCompanies, { loading: loadingCompanies }] = useLazyQuery(
    getCompanies,
    { variables: { page: -1, providerId } }
  );

  const buttonType = {
    background: theme.palette.common.black,
    hover: theme.palette.primary.main,
    active: theme.palette.primary.dark,
  };

  const initialValues = {
    planId: "",
    file: null,
    // replace: false,
    companyId: companyId ? companyId : "",
  };

  useEffect(() => {
    fetchPlans({
      variables: { type: "hmo" },
    })
      .then(({ data }) => {
        const options = (data?.getPlans?.data || []).map((option) => {
          return { key: option?.name, value: option?._id };
        });
        setPlanOptions(options);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [fetchPlans]);

  const setCompaniesOptions = (companyOptions) => {
    const data = companyOptions || [];
    const options = data.map((company) => {
      return { key: company?.name, value: company?._id };
    });
    setCompanies(options);
  };

  const handleCompanySearchChange = (company) => {
    fetchCompanies({ variables: { search: company } })
      .then((res) => {
        const companyOptions = res?.data?.getCompanies?.data;
        if (!companyOptions || companyOptions?.length === 0) return;
        setCompaniesOptions(companyOptions);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchCompanies = useCallback(
    debounce(handleCompanySearchChange, 1000),
    []
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(values)}
      validationSchema={uploadEnrolleeFileValidationSchema}
      validateOnChange={true}
      validateOnMount={false}
      validateOnBlur={true}
    >
      {({ isSubmitting, setFieldValue, setErrors }) => {
        return (
          <Form style={{ marginTop: "1rem" }}>
            <Alert severity="warning" sx={{ margin: "1rem 0rem" }}>
              <strong>CAUTION - </strong> Uploading a new file may overwrite the
              current file.
            </Alert>
            <Grid container direction="column" rowGap={3}>
              <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="select"
                  options={[{ key: "Plan", value: "" }, ...planOptions]}
                  placeholder="Select Heala Plan"
                  name="planId"
                />
              </Grid>

              {!companyId && (
                <Grid item container direction="column" gap={1}>
                  {/* make a fake auto complete to house existing company */}
                  <AutoComplete
                    id="companyId"
                    name="companyId"
                    size="small"
                    loading={loadingCompanies}
                    sx={{ minWidth: "200px" }}
                    // defaultValue={defaultCompany}
                    onInputChange={(e) => {
                      const value = e?.target?.value;
                      if (!value || value === "") return;
                      searchCompanies(value);
                    }}
                    onChange={(e, info) => {
                      const value = info?.value;
                      setFieldValue("companyId", value);
                    }}
                    options={companies}
                    clearOnBlur={false}
                    noOptionsText="Type to see options"
                    getOptionLabel={(option) => option.key}
                    renderOption={(props, option) => (
                      <li {...props}>{option?.key}</li>
                    )}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select company affiliated to enrollee(s)"
                      />
                    )}
                  />
                </Grid>
              )}

              {/* <Grid item container direction="column" gap={1}>
                <Grid container gap={1} alignItems="center">
                  <FormLabel>Replace existing data:</FormLabel>
                  <Checkbox
                    value={replace}
                    onChange={(e) => {
                      const value = e.target?.checked;
                      if(value) {
                        alert("The current data will be overwritten.");
                        setReplace(value);
                        setFieldValue("replace", value);
                      }
                      
                    }}
                  />
                </Grid>
              </Grid> */}

              <Grid item>
                <DragAndDrop
                  name="file"
                  maxFiles={1}
                  hasPreview={false}
                  uploadFunc={(file) => {
                    setErrors({});
                    setFieldValue("file", file);
                  }}
                />
              </Grid>
              {templateError && (
                <Grid style={{backgroundColor: "rgba(242, 24, 24, 0.1)", padding: "1rem", borderRadius: "8px"}}>
                <Typography sx={{color: "#f21818", fontWeight: 500 }}>Invalid Template Upload</Typography>
                <Typography sx={{color: "#f21818", fontWeight: 400, fontSize: "0.8em !important", whiteSpace: "pre-wrap" }}>
                  The columns in the uploaded table do not match the expected
                  format. Please re-download the template or contact our support
                  team if you need any assistance.
                </Typography>
              </Grid>
              )}
              <Grid item>
                <CustomButton
                  title="Upload file"
                  width="100%"
                  type={buttonType}
                  isSubmitting={isSubmitting}
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

UploadEnrolleeListForm.propTypes = {
  onSubmit: t.func.isRequired,
  templateError: t.bool.isRequired,
};
