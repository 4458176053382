// Patients Route TableHeader
export const patientsHeadCells = [
  {
    id: 0,
    label: "Date",
  },
  {
    id: 8,
    label: "Order ID",
  },
  {
    id: 12,
    label: "Doctor's Name",
  },
  {
    id: 2,
    label: "Patient's Name",
  },
  {
    id: 3,
    label: "Drugs Amount",
  },
  {
    id: 55,
    label: "Total Drugs",
  },
];
export const patientsHeadCells2 = [
  {
    id: 0,
    label: "Date",
  },
  {
    id: 8,
    label: "Test ID",
  },
  {
    id: 12,
    label: "Doctor's Name",
  },
  {
    id: 2,
    label: "Patient's Name",
  },
  {
    id: 59,
    label: "Total Tests",
  },

  {
    id: 3,
    label: "Test Amount",
  },
  {
    id: 55,
    label: "Delivery Fee",
  },
];
export const availabilityHeadCells10 = [
  { id: 2, label: "Day" },
  { id: 3, label: "Start Time" },
  { id: 300, label: "End Time" },
];

export const patientsHeadCells1 = [
  {
    id: 0,
    label: "User ID",
  },
  {
    id: 1,
    label: "Patient Name",
  },
  {
    id: 2,
    label: "Plan",
  },
  {
    id: 8,
    label: "Provider",
  },
  {
    id: 3,
    label: "Consultations",
  },
  // {
  //   id: 4,
  //   label: "Status",
  // },
];
export const ref = [
  {
    id: 0,
    label: "Date",
  },
  {
    id: 1,
    label: "Order ID",
  },
  {
    id: 2,
    label: "Doctor's Name",
  },
  {
    id: 8,
    label: "Patient's Name",
  },
  {
    id: 3,
    label: "Test Amount",
  },
  {
    id: 4,
    label: "Total Test",
  },
];
export const ref2 = [
  {
    id: 0,
    label: "Drug Name",
  },
  {
    id: 1,
    label: "Qty per pack",
  },
  {
    id: 2,
    label: "Drug Amount",
  },
  {
    id: 8,
    label: "",
  },
];
export const hcpsHeadCells5 = [
  { id: 0, label: "Medical ID" },
  { id: 1, label: "Name" },
  { id: 2, label: "Category" },
  { id: 3, label: "Consultations" },
  { id: 5, label: "Status" },
];
export const messagesHeadCell = [
  { id: 0, label: "Date" },
  { id: 9, label: "Referral ID" },
  { id: 91, label: "Test ID" },

  { id: 2, label: "Cancellation Reasons" },
  { id: 3, label: "Patient's Name" },
];
export const messagesHeadCells3 = [
  { id: 0, label: "Recipients" },
  { id: 1, label: "Subject" },
  { id: 2, label: "Date" },
  { id: 3, label: "Time" },
  { id: 4, label: "" },
];
export const emailHeader = [
  {
    id: 0,
    label: "Recipients",
  },
  {
    id: 6,
    label: "Subject",
  },
  {
    id: 4,
    label: "Entry Date",
  },
  {
    id: 2,
    label: "Plan",
  },

  {
    id: 8,
    label: "Email",
  },
  {
    id: 9,
    label: "",
  },
];
export const PermissionHeader = [
  {
    id: 0,
    label: "Permission",
  },
  {
    id: 6,
    label: "CRUD",
  },
  {
    id: 3,
    label: "",
  },
];
export const adminHeader = [
  {
    id: 0,
    label: "Admin Name",
  },
  {
    id: 1,
    label: "Role",
  },
  {
    id: 2,
    label: "",
  },
];
export const roleHeader = [
  {
    id: 0,
    label: "Role",
  },
  {
    id: 5,
    label: "Permission",
  },
  {
    id: 2,
    label: "",
  },
];

export const HCPHeader = [
  {
    id: 0,
    label: "Entry Date",
  },
  {
    id: 8,
    label: "Type",
  },
  {
    id: 1,
    label: "Name",
  },
  {
    id: 2,
    label: "Medical ID",
  },

  {
    id: 4,
    label: "",
  },
];
export const financeHeader = [
  {
    id: 0,
    label: "Entry Date",
  },
  {
    id: 1,
    label: "Time",
  },
  {
    id: 2,
    label: "Name",
  },

  {
    id: 3,
    label: "Subscription Plan",
  },
];
export const financeHeader2 = [
  {
    id: 0,
    label: " Amount",
  },
  {
    id: 1,
    label: "Customer",
  },
  {
    id: 2,
    label: "Plan Type",
  },

  {
    id: 30,
    label: "Provider",
  },
  {
    id: 34,
    label: "Paid On",
  },
];
export const earningHead = [
  {
    id: 0,
    label: "Entry Date",
  },
  {
    id: 1,
    label: "Time",
  },
  {
    id: 2,
    label: "Name",
  },

  {
    id: 3,
    label: "Subscription Plan",
  },
  {
    id: 31,
    label: "Amount",
  },
];
export const payoutHeaderss1 = [
  {
    id: 234,
    label: "Amount",
  },
  {
    id: 235,
    label: "Customer",
  },
  {
    id: 2350,
    label: "Provider",
  },
  {
    id: 2,
    label: "Received On",
  },
];
export const payoutHeaders = [
  {
    id: 0,
    label: "Date",
  },
  {
    id: 1,
    label: "Time",
  },
  {
    id: 2,
    label: "Name",
  },
  {
    id: 4,
    label: "Amount",
  },
  {
    id: 40,
    label: "Status",
  },
];
export const referralHeader = [
  {
    id: 0,
    label: "Date",
  },
  {
    id: 1,
    label: "Time",
  },
  {
    id: 2,
    label: " Name",
  },

  {
    id: 3,
    label: "Category",
  },
  {
    id: 4,
    label: "Patient's name",
  },
  {
    id: 5,
    label: "Status",
  },
  {
    id: 6,
    label: "",
  },
];
export const referralHeaderss = [
  {
    id: 0,
    label: "Date",
  },
  {
    id: 1,
    label: "Referral ID",
  },
  {
    id: 2,
    label: "Doctor",
  },

  {
    id: 3,
    label: "Patient",
  },

  {
    id: 8,
    label: "Type",
  },
];
export const subscriptionHeadersss = [
  {
    id: 108,
    label: "Name of plan",
  },
  {
    id: 1,
    label: "Amount",
  },
  {
    id: 2,
    label: " Description",
  },

  {
    id: 6,
    label: "Duration",
  },
  {
    id: 8,
    label: "",
  },
];
export const subscriptionHeader = [
  {
    id: 0,
    label: "Name of plan",
  },
  {
    id: 1,
    label: "Amount",
  },
  {
    id: 2,
    label: " Description",
  },

  {
    id: 3,
    label: "",
  },
];
export const payoutHeader = [
  {
    id: 0,
    label: "Date",
  },
  {
    id: 1,
    label: "Time",
  },
  {
    id: 2,
    label: " Name",
  },

  {
    id: 4,
    label: "Amount Paid Out ",
  },
  {
    id: 5,
    label: "Payout status",
  },
];
export const payoutHeadersss = [
  {
    id: 0,
    label: "Date",
  },
  {
    id: 1,
    label: "Time",
  },
  {
    id: 2,
    label: " Name",
  },

  {
    id: 4,
    label: "Amount Paid Out ",
  },
];
export const payoutHeaderss = [
  {
    id: 0,
    label: "Date",
  },
  {
    id: 1,
    label: "Time",
  },
  {
    id: 2,
    label: " Name",
  },
  {
    id: 4,
    label: "Amount Paid out",
  },
  {
    id: 5,
    label: "Payout status",
  },
];
export const pendingHeader = [
  {
    id: 0,
    label: "Date",
  },
  {
    id: 1,
    label: "Heala  ID",
  },
  {
    id: 2,
    label: " Name",
  },

  {
    id: 3,
    label: "Amount paid",
  },
  {
    id: 4,
    label: "Account Details",
  },
  {
    id: 5,
    label: "Bank",
  },
];
export const viewRefferalHeader = [
  {
    id: 0,
    label: "Name",
  },
  {
    id: 1,
    label: "Category",
  },
  {
    id: 2,
    label: " Available Time",
  },

  {
    id: 4,
    label: "",
  },
  {
    id: 5,
    label: "",
  },
];

// Patient > Consultation Route Table
export const consultationsHeadCells = [
  { id: 101, label: "Date" },
  { id: 100, label: "Name" },
  { id: 102, label: "Symptoms" },
  { id: 1000, label: "Contact" },
  { id: 104, label: "Type" },
  { id: 103, label: "Status" },
];

// Patients > Prescription Route Table
export const prescriptionsHeadCells = [
  { id: 200, label: "Date" },
  { id: 201, label: "Details" },
  { id: 202, label: "Dosage" },
];
export const consultationsHeadCells2 = [
  { id: 100, label: "Doctor" },
  { id: 101, label: "Date" },
  { id: 102, label: "Time" },
  { id: 103, label: "" },
  { id: 139, label: "" },
];
export const consultationsHeadCells4 = [
  { id: 100, label: "Date" },
  { id: 101, label: "Doctor" },
  { id: 102, label: "Symptoms" },
  { id: 105, label: "Contact" },
  { id: 133, label: "Type" },
  { id: 1232, label: "Status" },
];
export const editManagement = [
  {
    id: 110,
    label: "",
  },
  {
    id: 0,
    label: "Endpoints",
  },
  {
    id: 1,
    label: "Operations",
  },
];

// Patients > Medications Route Table
export const medicationsHeadCells = [
  { id: 300, label: "Date Prescribed" },
  { id: 301, label: "Medication Name" },
  { id: 302, label: "Prescription Types" },
  { id: 303, label: "Caregiver" },
];

// ------------------------------------

// Dashboard Route TableHeader
export const waitingHeadCells = [
  { id: 0, label: "User ID" },
  { id: 1, label: "Name" },
  { id: 2, label: "Waiting Time" },
  { id: 3, label: "" },
];

export const availabilityHeadCells = [
  { id: 0, label: "Doctor ID" },
  { id: 1, label: "Name" },
  { id: 2, label: "Day" },
  { id: 3, label: "Start Time" },
  { id: 300, label: "End Time" },
];

// HCPs HEADCELLS
export const hcpsHeadCells = [
  { id: 0, label: "Date" },
  { id: 1, label: "Time" },
  { id: 2, label: "Order Num" },
  { id: 3, label: "Patient's Name" },
  { id: 120, label: "Drug Amount" },
  { id: 12, label: "Total Drugs" },
];

// Partner's HEADCELLS
export const partnersHeadCells = [
  { id: 0, label: "Date" },
  { id: 1, label: "Time" },
  { id: 2, label: "Test ID" },
  { id: 3, label: "Patient's Name" },
  { id: 12, label: "Total Test" },
  { id: 120, label: "Test Amount" },
  { id: 122, label: "Delivery Fee" },
];
export const partnersHeadCells20 = [
  { id: 0, label: "Date" },
  { id: 1, label: "Time" },
  { id: 2, label: "Order ID" },
  { id: 3, label: "Patient's Name" },
  { id: 120, label: "Drugs Amount" },
  { id: 12, label: "Total Drugs" },
];
export const partnersHeadCells2 = [
  { id: 0, label: "Date" },
  { id: 1, label: "Time" },
  { id: 2, label: "Test ID" },
  { id: 3, label: "Patient's Name" },
  { id: 120, label: "Test Amount" },
  { id: 12, label: "Total Test" },
];

// Messages's HEADCELLS
export const messagesHeadCells = [
  { id: 24, label: "Date" },
  { id: 9, label: "Time" },
  { id: 2, label: "Order Num" },
  // { id: 22, label: "Cancellation's Reason" },
  { id: 3, label: "Patient's Name" },
];
// HCP Patients HEADCELLS
export const hcpPatientsHeadCells = [
  { id: 0, label: "User ID" },
  { id: 1, label: "Patient Name" },
  { id: 2, label: "" },
];

export const enrollesTableHeadCells = [
  { id: 1, label: "HMO ID" },
  { id: 0, label: "NAME" },
  {id:10, label: "COMPANY"},
  { id: 2, label: "NOC" },
  { id: 5, label: "HMO PLAN" },
  { id: 6, label: "EXPIRY" },
  { id: 7, label: "STATUS" },
  { id: 9, label: "" },
];

export const hmoPlansTableHeader = [
  {
    id: 8,
    label: "Name of plan",
  },
  /*   {
    id: 1,
    label: "Amount",
  }, */
  /* {
    id: 2,
    label: " Description",
  }, */
  /* {
    id: 4,
    label: " Provider",
  }, */
  {
    id: 6,
    label: "Heala access plan",
  },
  {
    id: 8,
    label: "",
  },
];

export const prescriptionTable = [
  {
    id: 0,
    label: "Drug Name",
  },
  {
    id: 1,
    label: "Dosage",
  },
  {
    id: 2,
    label: "Frequency",
  },
  {
    id: 8,
    label: "Mode",
  },
];

export const allConsultationsHeader = [
  { id: 100, label: "Date" },
  { id: 101, label: "Doctor" },
  { id: 107, label: "Patient" },
  { id: 102, label: "Symptoms" },
  { id: 105, label: "Contact" },
  { id: 133, label: "Type" },
  { id: 1232, label: "Status" }
];

export const patientPrescriptionHeadCells = [
  {
    id: 0,
    label: "Date"
  },
  {
    id: 1,
    label: "Doctor"
  },
  {
    id: 2,
    label: "Drugs"
  },
  {
    id: 3,
    label: ""
  }
];

export const patientAppointmentHeadCells = [
  { id: 100, label: "Doctor" },
  { id: 101, label: "Date" },
  { id: 102, label: "Time" },
  { id: 103, label: "Contact Medium" },
];

export const doctorAppointmentHeadCells = [
  { id: 100, label: "Patient" },
  { id: 101, label: "Date" },
  { id: 102, label: "Time" },
  { id: 103, label: "Contact Medium" },
];

export const testRefTableHeader = [
  {
    id: 0,
    label: "Test Name"
  },
  {
    id: 1,
    label: "Turnaround time (TAT)"
  },
];
