import { SvgIcon } from "@mui/material";
import React from "react";

function PeopleIcon(props) {
  return (
    <SvgIcon viewBox="0 0 22 22" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.89374 3.79956C7.60133 3.74307 8.27304 3.46476 8.81324 3.00426C9.42337 2.48461 10.1986 2.19922 11 2.19922C11.8015 2.19922 12.5767 2.48461 13.1868 3.00426C13.727 3.46476 14.3988 3.74307 15.1063 3.79956C15.9054 3.86343 16.6556 4.20982 17.2224 4.77666C17.7893 5.34349 18.1357 6.09369 18.1995 6.89276C18.2556 7.60006 18.5339 8.27216 18.9948 8.81226C19.5145 9.42239 19.7999 10.1976 19.7999 10.9991C19.7999 11.8005 19.5145 12.5757 18.9948 13.1859C18.5343 13.7261 18.256 14.3978 18.1995 15.1054C18.1357 15.9044 17.7893 16.6546 17.2224 17.2215C16.6556 17.7883 15.9054 18.1347 15.1063 18.1986C14.3988 18.2551 13.727 18.5334 13.1868 18.9939C12.5767 19.5135 11.8015 19.7989 11 19.7989C10.1986 19.7989 9.42337 19.5135 8.81324 18.9939C8.27304 18.5334 7.60133 18.2551 6.89374 18.1986C6.09467 18.1347 5.34447 17.7883 4.77763 17.2215C4.2108 16.6546 3.8644 15.9044 3.80054 15.1054C3.74405 14.3978 3.46574 13.7261 3.00524 13.1859C2.48559 12.5757 2.2002 11.8005 2.2002 10.9991C2.2002 10.1976 2.48559 9.42239 3.00524 8.81226C3.46574 8.27207 3.74405 7.60035 3.80054 6.89276C3.8644 6.09369 4.2108 5.34349 4.77763 4.77666C5.34447 4.20982 6.09467 3.86343 6.89374 3.79956ZM15.0777 9.57676C15.2781 9.3693 15.389 9.09144 15.3865 8.80302C15.384 8.51461 15.2683 8.23871 15.0643 8.03476C14.8604 7.83081 14.5845 7.71513 14.2961 7.71262C14.0077 7.71012 13.7298 7.82099 13.5223 8.02136L9.90004 11.6437L8.47774 10.2214C8.27028 10.021 7.99241 9.91012 7.704 9.91262C7.41558 9.91513 7.13969 10.0308 6.93574 10.2348C6.73179 10.4387 6.6161 10.7146 6.6136 11.003C6.61109 11.2914 6.72197 11.5693 6.92234 11.7768L9.12234 13.9768C9.32862 14.183 9.60836 14.2988 9.90004 14.2988C10.1917 14.2988 10.4715 14.183 10.6777 13.9768L15.0777 9.57676Z"
      />
    </SvgIcon>
  );
}

export default PeopleIcon;
