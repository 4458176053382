import React, { useState, useEffect } from "react";
import { ReactComponent as DangerIcon } from "assets/images/Vector.svg";
// import { makeStyles } from "@mui/styles";
import { ReactComponent as SearchIcon } from "assets/images/searchs.svg";
import { ReactComponent as Users } from "assets/images/users.svg";
import { useAlert } from "components/hooks";
import {
  IconButton,
  Grid,
  InputBase,
  Typography,
  Card,
  Chip,
  Divider,
  MenuItem,
} from "@mui/material";
import { CustomButton, Loader } from "components/Utilities";
import { FormikControl } from "components/validation";
import { Formik, Form } from "formik";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  getUserTypes,
  getUserTypeProviders,
} from "components/graphQL/useQuery";
import { getEnrollees } from "components/graphQL/useQuery";
import { useTheme } from "@mui/material/styles";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";
import { isPast } from "date-fns";
const Validations = () => {
  const theme = useTheme();
  const buttonType = {
    background: theme.palette.common.black,
    hover: theme.palette.primary.main,
    active: theme.palette.primary.dark,
  };
  const useStyles = makeStyles(() => ({
    badge: {
      "&.MuiChip-root": {
        fontSize: "2.5rem !important",
        padding: "2.5rem 1rem",
        height: "",

        borderRadius: "1.3rem",
      },
    },
  }));
  const classes = useStyles();
  const [state, setState] = useState([]);
  const [formState, setFormState] = useState();
  const [inputState, setInputState] = useState();
  const validationSchema = Yup.object({
    hmo: Yup.string("Enter your HMO name")
      .trim()
      .required("HMO Name is required"),
    id: Yup.string("Enter your Customer ID")
      .trim()
      .required("Customer ID is required"),
  });
  const validationSchema2 = Yup.object({
    firstName: Yup.string("Enter your First name")
      .trim()
      .required("HMO Name is required"),
    lastName: Yup.string("Enter your First name")
      .trim()
      .required("HMO Name is required"),
    hmoId: Yup.string("Enter your  HMO plan")
      .trim()
      .required("HMO plan is required"),
    date: Yup.string("Select a date")
      .trim()
      .required("Expiry Date is required"),
  });
  const [validate, { data: validateData }] = useLazyQuery(getEnrollees);
  const { displayMessage } = useAlert();
  const { data, loading: load } = useQuery(getUserTypes);
  const [fetchProvider, { data: dat, loading }] =
    useLazyQuery(getUserTypeProviders);
  const [userTypeId, setUserTypeIds] = useState("");
  const [providers, setProviders] = useState([]);
  const [input, setInput] = useState("");
  const [details, setDetails] = useState("");

  const handleChange = (e) => {
    const { value } = e.target;
    setInputState(value);
    setInput(value.toLowerCase());
  };
  useEffect(() => {
    setUserTypeIds(data?.getUserTypes?.userType[0]._id);
  }, [data]);
  useEffect(() => {
    if (userTypeId) {
      fetchProvider({
        variables: {
          id: userTypeId,
        },
      });
    }
    //eslint-disable-next-line
  }, [userTypeId]);
  useEffect(() => {
    if (dat) {
      setProviders(dat?.getUserTypeProviders?.provider);
    }
  }, [dat]);
  useEffect(() => {
    if (validateData?.getEnrollees?.data.length >= 1) {
      setDetails(validateData?.getEnrollees?.data[0]);
    }
  }, [validateData]);

  useEffect(() => {
    const filteredData = providers.filter((el) => {
      if (input === "") {
        return el;
      }
      //return the item which contains the user input
      else {
        return el.name.toLowerCase().includes(input);
      }
    });
    setState(filteredData);
    //eslint-disable-next-line
  }, [input]);
  const handleSelect = (item) => {
    const { name, _id } = item;

    setFormState({
      hmo: name,
      userTypeId: _id,
    });
    setState([]);
    setInputState(name);
  };
  const onSubmit = async (values) => {
    const { id } = values;
    try {
      const { data } = await validate({
        variables: {
          hmoId: id,
          providerId: formState.userTypeId,
        },
      });

      if (data.getEnrollees?.data.length >= 1) {
        displayMessage("success", "Validation Successful");
      } else {
        displayMessage("error", "No Enrolle found");
      }
    } catch (error) {
      displayMessage("error", error);
    }
  };

  if (loading || load) return <Loader />;
  const expiredValue = isPast(new Date().getTime(), new Date(details?.expiryDate).getTime());

  return (
    <Grid container sx={{maxWidth: {xs: "auto", sm: "500px"}, margin: "auto"}} gap={4}>
      <Grid item sx={{ py: 2.5 }}>
        <Grid
          container
          flexWrap="nowrap"
          alignItems="center"
          gap={4}
          sx={{
            py: 2,
            px: {xs: 2, sm: 4, lg: 6},
            color: "#3E5EA9",
            background: "#ECEFF6",
            fontWeight: 300,
            fontSize: "2rem",
            lineHeight: "2.5rem",
            textAlign: "center",
          }}
        >
          <Grid item>
            <DangerIcon sx={{ font: "inherit" }} />
          </Grid>
          <Typography variant="body2" sx={{ font: "inherit" }}>
            Validate the HMO authenticity of customers through their ID, view
            profile and details of their active plans
          </Typography>
        </Grid>
      </Grid>
      <Grid item sx={{width: "100%", position: "relative" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          flexWrap="nowrap"
          sx={{ height: "6rem" }}
        >
          <Grid
            item
            sx={{
              height: "inherit",
              width: "100%",
              color: "#E6E6E6",
              background: "rgba(230, 230, 230, 0.5)",
              borderRadius: "4rem",
              display: "flex",
              alignItems: "center",
              padding: "1.6rem !important",
              paddingTop: 0,
              fontWeight: 300,
              fontSize: "1.6rem",
              letterSpacing: "-0.01em",
            }}
          >
            <IconButton
              type="button"
              aria-label="search"
              sx={{ color: "#6C6C6C", fontSize: "small" }}
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ flex: 1, p: 0, font: "inherit" }}
              size="large"
              placeholder="Search by HMO Name"
              onChange={handleChange}
              value={inputState}
            />
          </Grid>
        </Grid>
        {state.length > 0 && input !== "" && (
          <Card
            item
            xs={4}
            sx={{
              mt: 2,
              position: "absolute",
              zIndex: 300,
              maxHeight: "20rem",
              background: "white",
              padding: "0",
              width: "100%",
              overflowY: "scroll",
              boxShadow: "-1px 0px 10px -2px rgba(0,0,0,0.15)",
            }}
          >
            {state?.map((item) => (
              <MenuItem
                key={item.name}
                sx={{
                  cursor: "pointer",
                  display: "block",
                  fontSize: "1.8rem",
                }}
                onClick={() => handleSelect(item)}
              >
                {item.name}
              </MenuItem>
            ))}
          </Card>
        )}
      </Grid>
      <Grid item sx={{width: "100%" }}>
        <Formik
          initialValues={
            formState?.hmo ? formState : { hmo: "", id: "", userTypeId: "" }
          }
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnMount={false}
          validateOnBlur={false}
          enableReinitialize
        >
          {({ isSubmitting, dirty, isValid }) => {
            return (
              <Form style={{ marginTop: "1rem" }}>
                <Grid
                  container
                  justifyContent="space-between"
                  flexDirection={{xs: "column", md: "row"}}
                  flexWrap="nowrap"
                  rowGap={2}
                  columnGap={2}
                >
                  <Grid item>
                    <FormikControl
                      control="input"
                      label="HMO Name"
                      id="hmo"
                      name="hmo"
                      placeholder="Enter HMO name"
                      disabled
                    />
                  </Grid>
                  <Grid item>
                    <FormikControl
                      control="input"
                      label="HMO ID"
                      id="id"
                      name="id"
                      placeholder="Enter HMO ID"
                    />
                  </Grid>

                  <Grid item alignSelf={{xs: "", lg: "flex-end"}}>
                    <CustomButton
                      title="Validate"
                      width="100%"
                      borderRadius={12}
                      type={buttonType}
                      isSubmitting={isSubmitting}
                      disabled={!dirty || !isValid || isSubmitting}
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      <Grid item sx={{ }}>
        <Card sx={{ p: 4, borderRadius: 2.5, boxShadow: "none" }}>
          <Grid item container>
            <Grid item container direction="column" gap={0.5}>
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: "2.4rem",
                  lineHeight: "3rem",
                  letterSpacing: " -0.01em",
                  color: "#6C6C6C",
                }}
              >
                HMO Details
              </Typography>
              <Divider sx={{ mb: 1 }} />
              <Grid item container justifyContent="flex-end" sx={{ mb: 6 }}>
                {details?.expiryDate && (
                  <Chip
                    label={expiredValue ? "Expired" : "Active"}
                    className={classes.badge}
                    style={{
                      background: !expiredValue
                        ? theme.palette.common.lightGreen
                        : theme.palette.common.lightRed,
                      color: !expiredValue
                        ? theme.palette.common.green
                        : theme.palette.common.red,
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item container gap={4} flexDirection={{xs: "column"}} flexWrap="nowrap">
              <Grid
                item
                sx={{ width: 200, border: "1px solid #E6E6E6", borderRadius: 4, flexGrow: 0 }}
              >
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{  }}
                >
                  {details?.photo ? (
                    <img
                      src={details?.photo}
                      alt={details?.firstName}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "1.6rem",
                      }}
                    />
                  ) : (
                    <Users />
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Formik
                    initialValues={
                      details?.firstName
                        ? details
                        : {
                            firstName: "",
                            lastName: "",
                            hmoId: "",
                            expiryDate: "",
                          }
                    }
                    onSubmit={onSubmit}
                    validationSchema={validationSchema2}
                    validateOnChange={false}
                    validateOnMount={false}
                    validateOnBlur={false}
                  >
                    {({ isSubmitting, dirty, isValid }) => {
                      return (
                        <Form
                          style={{
                            marginTop: "1rem",
                            width: "100%",
                          }}
                        >
                          <Grid container direction="column" gap={2}>
                            <Grid item container gap={3} flexDirection={{xs: "column", sm: "row"}} flexWrap="nowrap">
                              <Grid item>
                                <FormikControl
                                  control="input"
                                  label="First Name"
                                  id="firstName"
                                  name="firstName"
                                  placeholder="Enter Your First Name"
                                  value={details?.firstName}
                                  disabled
                                />
                              </Grid>
                              <Grid item>
                                <FormikControl
                                  control="input"
                                  label="Last Name"
                                  id="lastName"
                                  name="lastName"
                                  placeholder="Enter Your Last Name"
                                  value={details?.lastName}
                                  disabled
                                />
                              </Grid>
                            </Grid>
                            <Grid item container gap={3} flexDirection={{xs: "column", sm: "row"}} flexWrap="nowrap">
                              <Grid item>
                                <FormikControl
                                  control="input"
                                  label="HMO Plan"
                                  id="hmoId"
                                  name="hmoId"
                                  placeholder="HMO PLAN"
                                  value={details?.plan}
                                  disabled
                                />
                              </Grid>
                              <Grid item>
                                <FormikControl
                                  control="time"
                                  label="Expiry Date"
                                  id="date"
                                  name="expiryDate"
                                  placeholder="Expiry Date"
                                  value={details?.expiryDate}
                                  disabled
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Form>
                      );
                    }}
                  </Formik>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Validations;
