import React, { useState } from "react";
import Copy from "components/Copy";
import { Form, Formik } from "formik";
import { useAlert } from "components/hooks";
import { useMutation } from "@apollo/client";
import { Loader } from "components/Utilities";
import { useTheme } from "@mui/material/styles";
import { LoginInput } from "components/validation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { generateAccountAPIKey } from "components/graphQL/Mutation";
import { Button, Grid, InputAdornment, Typography } from "@mui/material";

const GenerateAPIKeyForm = ({ accountID, accountInfo, setAccountInfo }) => {
  const theme = useTheme();
  const { displayMessage, getErrorMsg } = useAlert();
  const [showAPIKey, setShowAPIKey] = useState(false);
  const [showProviderID, setProviderID] = useState(false);
  const [generateAPIKey, { loading: loadingAPIKey }] = useMutation(
    generateAccountAPIKey
  );

  const generate = () => {
    generateAPIKey({
      variables: { accountId: accountID },
    })
      .then(({ data }) => {
        displayMessage("success", "API key generated!");
        setAccountInfo({
          apiKey: data?.generateAccountApiKey?.account?.apiKey,
          providerId: accountInfo?.providerId,
        });
      })
      .catch((error) => {
        console.error(error);
        const errMsg = getErrorMsg(error);
        displayMessage("error", errMsg);
      });
  };

  return (
    <>
      <Formik>
        {() => {
          return (
            <Form>
              <Grid>
                <Grid>
                  <Typography>API KEY</Typography>
                  {!accountInfo.apiKey ? (
                    <Typography
                      sx={{
                        color: theme.palette.common.danger,
                        fontSize: "14px",
                        marginTop: "1rem",
                      }}
                    >
                      No API generated
                    </Typography>
                  ) : (
                    <Grid container>
                      <Grid item flex="1 1 0%">
                        <LoginInput
                          id="api-key"
                          value={`${accountInfo?.apiKey}` || ""}
                          autoFocus={false}
                          type={showAPIKey ? "text" : "password"}
                          hasStartIcon={false}
                          endAdornment={
                            <InputAdornment
                              position="end"
                              onClick={() => setShowAPIKey((prev) => !prev)}
                              style={{ cursor: "pointer" }}
                            >
                              {showAPIKey ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </InputAdornment>
                          }
                        />
                      </Grid>
                      <Grid item sx={{ display: "flex", alignItems: "center" }}>
                        <Copy
                          size="large"
                          text={accountInfo?.apiKey}
                          name="API key"
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Button
                    sx={{
                      marginTop: "1rem",
                      color: theme.palette.common.blue,
                    }}
                    onClick={() => generate()}
                  >
                    {loadingAPIKey ? (
                      <Loader />
                    ) : !accountInfo?.apiKey ? (
                      "Generate API key"
                    ) : (
                      "Re-generate API key"
                    )}
                  </Button>
                </Grid>
                <Grid marginTop="4rem">
                  <Typography>PROVIDER ID</Typography>
                  <Grid container>
                    <Grid item flex="1 1 0%">
                      <LoginInput
                        id="api-key"
                        value={`${accountInfo?.providerId?._id}` || ""}
                        autoFocus={false}
                        type={showProviderID ? "text" : "password"}
                        hasStartIcon={false}
                        endAdornment={
                          <InputAdornment
                            position="end"
                            onClick={() => setProviderID((prev) => !prev)}
                            style={{ cursor: "pointer" }}
                          >
                            {showProviderID ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </InputAdornment>
                        }
                      />
                    </Grid>
                    <Grid item sx={{ display: "flex", alignItems: "center" }}>
                      <Copy
                        size="large"
                        text={accountInfo?.providerId}
                        name="Provider ID"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default GenerateAPIKeyForm;
