import { Autocomplete } from "@mui/material";
import React from "react";
const { makeStyles } = require("@mui/styles");

export const useAutoCompleteStyles = makeStyles((theme) => ({
  autoComplete: {
    "& .MuiInputBase-root": {
      minWidth: "150px",
      fontWeight: "200 !important",
      color: `${theme.palette.common.grey} !important`,
      backgroundColor: "#FAFAFA",
      height: (props) => props?.height || "48px !important",
      fontSize: "14px !important",
      borderRadius: "8px",
      border: "1px solid #E0E0E0 !important",

      "&:disabled": {
        backgroundColor: "#E0E0E0 !important"
      },

      "&>fieldset": {
        border: 0
      }
    }
  }
}));

export const AutoComplete = ({height="48px", ...props}) => {
    const classes = useAutoCompleteStyles({ height });
    return (
        <Autocomplete className={classes.autoComplete} {...props} />
    )
}