import React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import { useCopy } from "components/hooks";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Typography } from "@mui/material";

const Copy = ({ text, name, size = "medium", ...props }) => {
  const { copyToClipBoard } = useCopy();

  return (
    <IconButton
      onClick={() => copyToClipBoard(text, name)}
      sx={{display: "block", color: "inherit" }}
      {...props}
    >
      <ContentCopyIcon fontSize={size} />
      {props.showText && <Typography sx={{display: "block"}}>Copy</Typography>}
    </IconButton>
  );
};

Copy.propTypes = {
  text: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

export default Copy;
