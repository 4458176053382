import { SvgIcon } from "@mui/material";
import React from "react";

function MarkIcon(props) {
  return (
    <SvgIcon width="24" height="25" viewBox="0 0 24 25" {...props}>
      <rect y="0.5" width="24" height="24" rx="4" fill="#F1FAF5" />
      <path
        d="M9.95801 17.5L5.20801 12.75L6.39551 11.5625L9.95801 15.125L17.6038 7.47913L18.7913 8.66663L9.95801 17.5Z"
        fill="#3EA584"
      />
    </SvgIcon>
  );
}

export default MarkIcon;
