import React, { useEffect, useState } from "react";
import { dateMoment } from "components/Utilities/Time";
import { useHistory } from "react-router-dom";
import { Grid, Chip, Typography, TableRow, TableCell } from "@mui/material";
import { Loader } from "components/Utilities";
import { EnhancedTable, NoData, EmptyTable } from "components/layouts";
import { consultationsHeadCells4 } from "components/Utilities/tableHeaders";
import { useActions } from "components/hooks/useActions";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import {
  changeHospitalTableLimit,
  handleHospitalPageChange,
} from "helpers/filterHelperFunctions";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { getConsultations } from "components/graphQL/useQuery";
import TableLayout from "components/layouts/TableLayout";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    "&.css-1jilxo7-MuiTableCell-root": {
      fontSize: "1.25rem",
    },
  },
  badge: {
    "&.MuiChip-root": {
      fontSize: "1.5rem",
      borderRadius: "1.5rem",
    },
  },

  button: {
    "&.MuiButton-root": {
      background: "#fff",
      color: theme.palette.common.grey,
      textTransform: "none",
      borderRadius: "2rem",
      display: "flex",
      alignItems: "center",
      padding: "1rem",
      maxWidth: "12rem",

      "&:hover": {
        background: "#fcfcfc",
      },

      "&:active": {
        background: "#fafafa",
      },

      "& .MuiButton-endIcon>*:nth-of-type(1)": {
        fontSize: "1.2rem",
      },

      "& .MuiButton-endIcon": {
        marginLeft: ".3rem",
      },
    },
  },
}));

const Consultations = () => {
  const [pageInfo, setPageInfo] = useState({
    page: 0,
    totalPages: 1,
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    totalDocs: 0,
  });
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { patientConsultation } = useActions();
  const { patientId } = useParams();
  const [fetchConsultations, { loading, data, error }] = useLazyQuery(
    getConsultations,
    { variables: { id: patientId, orderBy: "-createdAt" } }
  );

  useEffect(() => {
    fetchConsultations();
  }, [fetchConsultations, patientId]);

  const [consultations, setConsultations] = useState([]);
  useEffect(() => {
    if (data) {
      setConsultations(data?.getConsultations?.data);
      setPageInfo(data?.getConsultations?.pageInfo);
    }
  }, [data, consultations, patientConsultation]);

  if (loading) return <Loader />;
  if (error) return <NoData error={error} />;

  return (
    <Grid container gap={2} flexWrap="nowrap" direction="column" height="100%">
      <Grid
        item
        container
        flexWrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item flex={1}>
          <Typography variant="h2">Consultations</Typography>
        </Grid>
        {/* <Grid item>
          <FilterList options={filterOptions} title="Filter" />
        </Grid> */}
      </Grid>
      <TableLayout>
        {consultations.length > 0 ? (
          <Grid item container direction="column" height="100%">
            <EnhancedTable
              headCells={consultationsHeadCells4}
              rows={consultations}
              paginationLabel="Patients per page"
              hasCheckbox={false}
              changeLimit={(e) =>
                changeHospitalTableLimit(fetchConsultations, {
                  first: e,
                  id: patientId,
                  orderBy: "-createdAt",
                })
              }
              dataPageInfo={pageInfo}
              handlePagination={async (page) =>
                handleHospitalPageChange(fetchConsultations, page, pageInfo, {
                  id: patientId,
                  orderBy: "-createdAt",
                })
              }
            >
              {consultations.map((row, index) => {
                const {
                  doctor,
                  createdAt,
                  symptoms,
                  _id,
                  contactMedium,
                  type,
                  status,
                } = row;
            
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={_id}
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      history.push(
                        `/patients/${patientId}/consultations/case-note/${_id}`
                      )
                    }
                  >
                    <TableCell align="left" className={classes.tableCell}>
                      {dateMoment(createdAt)}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCell}
                      style={{ maxWidth: "25rem" }}
                    >
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontSize: "1.25rem" }}>
                          {doctor
                            ? `${doctor?.firstName} ${doctor?.lastName}`
                            : "No Doctor"}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Grid container gap={1}>
                        {symptoms
                          ? symptoms.map((i) => {
                              return <p key={i.name}>{i.name}</p>;
                            })
                          : "No Value"}
                      </Grid>
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCell}
                      style={{
                        color: theme.palette.common.grey,
                        minWidth: "6rem",
                      }}
                    >
                      {contactMedium ? contactMedium : "No Value"}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCell}
                      style={{
                        color: theme.palette.common.grey,
                      }}
                    >
                      {type ? type : "No Value"}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classes.tableCell}
                      style={{
                        color: theme.palette.common.grey,
                      }}
                    >
                      <Chip
                        label={status ? status : "No Value"}
                        className={classes.badge}
                        style={{
                          background:
                            status === "completed" || status === "ongoing"
                              ? theme.palette.common.lightGreen
                              : theme.palette.common.lightRed,
                          color:
                            status === "completed" || status === "ongoing"
                              ? theme.palette.common.green
                              : theme.palette.common.red,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </EnhancedTable>
          </Grid>
        ) : (
          <EmptyTable
            headCells={consultationsHeadCells4}
            paginationLabel="Patients per page"
          />
        )}
      </TableLayout>
    </Grid>
  );
};

export default Consultations;
