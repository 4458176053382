export const SET_PAGE = 'SET_PAGE'
export const SET_ROWS_PER_PAGE = 'SET_ROWS_PER_PAGE'
export const SET_SELECTED_ROWS = 'SET_SELECTED_ROWS'
export const EMAIL_DETAILS = 'EMAIL_DETAILS'
export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'
export const GET_USER = 'GET_USER'
export const REFRESH_USER = 'REFRESH_USER'
export const USER_DETAIL = 'USER_DETAIL'
export const GET_PATIENT_CONSULTATION = 'GET_PATIENT_CONSULTATION'
export const GET_DOCTOR_CONSULTATION = 'GET_DOCTOR_CONSULTATION'
export const CHAT = 'CHAT'
