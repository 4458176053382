import React, { useCallback, useState } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useAlert } from "components/hooks";
import { Loader } from "components/Utilities";
import MainModal from "components/modals/MainModal";
import { useMutation, useQuery } from "@apollo/client";
import ConfirmModal from "components/modals/ConfirmModal";
import {
  deactivateCompanyEnrollee,
  getCompanies,
  reactivateCompanyEnrollee,
} from "components/graphQL/useQuery";
import { deleteCompany } from "components/graphQL/Mutation";
import AddEditCompanyForm from "components/Forms/AddEditCompany";
import { AddInfoButton, InfoCard } from "components/cards/InfoCard";

const useStyles = makeStyles((theme) => ({
  deactivateBtn: {
    "&:hover": {
      color: "#fff",
      backgroundColor: theme.palette.common.danger,
    },
  },

  delBtn: {
    "&:hover": {
      color: "#fff",
      backgroundColor: theme.palette.common.danger,
      borderRadius: "0px 0px 10px 10px",
    },
  },
}));

const Companies = () => {
  const classes = useStyles();
  const [addModal, setAddModal] = useState(false);
  const { displayAlert, getErrorMsg } = useAlert();
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const providerId = localStorage.getItem("partnerProviderId");
  const [editInitialValues, setEditInitialValues] = useState({});
  const [companyIdToDelete, setCompanyIdToDelete] = useState(null);
  const [deactivateCompany, { loading: deactivating }] = useMutation(
    deactivateCompanyEnrollee
  );
  const [reactivateCompany, { loading: reactivating }] = useMutation(
    reactivateCompanyEnrollee
  );
  const [removeCompany, { loading: delCompanyLoading }] =
    useMutation(deleteCompany);
  const {
    data: companies,
    error: companyError,
    loading: loadingCompanies,
    refetch: refetchCompanies,
  } = useQuery(getCompanies, {
    variables: { providerId, orderBy: "-createdAt", page: -1 },
  });

  const deactivateReactivateCompany = useCallback(async (companyId, action) => {
    try {
      const isDeactivate = action === "deactivate";
      const successMsg = isDeactivate
        ? "Successfully deactivated company."
        : "Successfully reactivated company.";
      const { data } = await (isDeactivate
        ? deactivateCompany({ variables: { companyId } })
        : reactivateCompany({ variables: { companyId } }));
      if (data) displayAlert("success", successMsg);
    } catch (error) {
      const errMsg = getErrorMsg(error);
      displayAlert("error", errMsg);
    }
  }, [deactivateCompany, displayAlert, getErrorMsg, reactivateCompany]);

  if (companyError)
    <div>
      <p>Something went wrong. Try again!</p>
    </div>;
  return (
    <Grid container gap={4}>
      <Grid
        item
        container
        gap={2}
        spacing={2}
        sx={{ justifyContent: "flex-end", pt: 3 }}
      >
        <button
          disabled={loadingCompanies}
          style={{
            cursor: "pointer",
            border: "none",
            background: "transparent",
          }}
          onClick={() => {
            setAddModal(true);
          }}
        >
          <AddInfoButton text="Add new company" />
        </button>
      </Grid>
      {loadingCompanies || delCompanyLoading ? (
        <Loader />
      ) : (
        <Grid
          item
          container
          sx={{
            display: "grid",
            gridTemplateColumns: {xs: "repeat(1,1fr)", sm: "repeat(2,1fr)", lg: "repeat(3,1fr)"},
            gap: "1.5rem",
          }}
        >
          {(companies?.getCompanies?.data || []).map((company, idx) => {
            const count =
              (company?.enrolleeCount || 0) < 2
                ? `${company?.enrolleeCount} member`
                : `${company?.enrolleeCount} members`;

            const editValues = {
              _id: company?._id || "",
              name: company?.name || "",
              logo: company?.logo || "",
            };

            return (
              <Grid key={idx} item flex={1}>
                <InfoCard
                  imgUrl={company?.icon}
                  imgAlt={company?.name}
                  linkTo={`companies/${company?._id}`}
                  subTitle={`${count}` || "N/A enrollees"}
                  title={company?.name}
                  onClickEdit={() => {
                    setEditInitialValues(editValues);
                    setEditModal(true);
                  }}
                  onClickDelete={() => {
                    setCompanyIdToDelete(company?._id);
                    setDeleteModal(true);
                  }}
                  moreOptions={[
                    {
                      text: "Deactivate",
                      className: classes.deactivateBtn,
                      onClick: () => null /* deactivateReactivateCompany(company?._id, company) */,
                    },
                  ]}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      <Grid>
        {/* CONFIRM DELETE MODAL */}
        <ConfirmModal
          open={deleteModal}
          setOpen={setDeleteModal}
          title={`Delete ${
            (companies?.getCompanies?.data || []).filter(
              (c) => c._id === companyIdToDelete
            )[0]?.name
          } Company`}
          confirmationMsg=""
          onConfirm={async () => {
            try {
              setDeleteModal(false);
              await removeCompany({
                variables: { companyId: companyIdToDelete },
              });
              displayAlert("success", "Company deleted successfully.");
              refetchCompanies();
            } catch (error) {
              console.error(error);
              const errMsg = getErrorMsg(error);
              displayAlert("error", errMsg);
            }
          }}
          onCancel={() => {
            setDeleteModal(false);
          }}
        />
        {/* ADD MODAL */}
        <MainModal isOpen={addModal} setIsOpen={setAddModal} headerText="">
          <AddEditCompanyForm
            type="add"
            initialValues={{
              name: "",
              // email: "",
              // phone: "",
              logo: "",
            }}
            onSuccess={() => {
              refetchCompanies();
              setAddModal(false);
            }}
          />
        </MainModal>

        {/* EDIT MODAL */}
        <MainModal isOpen={editModal} setIsOpen={setEditModal} headerText="">
          <AddEditCompanyForm
            type="edit"
            initialValues={editInitialValues}
            onSuccess={() => {
              refetchCompanies();
              setEditModal(false);
            }}
          />
        </MainModal>
      </Grid>
    </Grid>
  );
};

export default Companies;
