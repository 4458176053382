import React from "react";
import { Field, ErrorMessage } from "formik";
import { TextError } from "components/Utilities";

import { makeStyles } from "@mui/styles";
import { FormLabel, Grid, Input as MUIInput } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  input: {
    ...theme.typography.input,
    borderRadius: ".5em",
    padding: "1em 1.5em",
    "& .MuiInputBase-root:hover": {
      border: "1px solid red"
    },
    "&.MuiInputBase-root:before" : {
      border: "none",
    },
    "&.MuiInputBase-root:after" : {
      border: "none",
    },
    "&.MuiInputBase-root:hover:before" : {
      border: "none",
    },
    "&.MuiInputBase-root:focus:before" : {
      border: "none",
    },
    "&.MuiInputBase-root.Mui-disabled": {
      backgroundColor: "#f1f1f1"
    }
  },
  FormLabel: {
    "&.MuiFormLabel-root": {
      ...theme.typography.FormLabel,
    },
  },
}));

const Input = (props) => {
  const { label, name, ...rest } = props;
  const classes = useStyles();
  return (
    <Grid container direction="column" gap={1}>
      <FormLabel component="legend" className={classes.FormLabel}>
        {label}
      </FormLabel>
      <Field
        id={name}
        name={name}
        className={classes.input}
        as={MUIInput}
        {...rest}
        style={{ minHeight: 50 }}
      />
      <ErrorMessage name={name} component={TextError} />
    </Grid>
  );
};

export default Input;
