import axios from "axios"

export const restApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

restApi.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Api-Key ${process.env.REACT_APP_DEFAULT_API_KEY}`;
    return config;
  }
);

