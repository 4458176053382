import React from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { TextError } from "components/Utilities";
import { TextField, FormLabel, Grid } from "@mui/material";
import PropTypes from "prop-types";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { makeStyles } from "@mui/styles";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
const useStyles = makeStyles((theme) => ({
  input: {
    // ...theme.typography.input,
  },
  FormLabel: {
    "&.MuiFormLabel-root": {
      ...theme.typography.FormLabel,
    },
  },
}));

const Times = ({ name, value, onBlur, disabled, placeholder }) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopTimePicker
        name={name}
        onChange={(value) => setFieldValue(name, value)}
        value={value}
        onBlur={onBlur}
        className={classes.input}
        renderInput={({ InputProps, inputProps, ...props }) => {
          inputProps.placeholder = placeholder;
          return (
            <TextField
              InputProps={InputProps}
              inputProps={inputProps}
              disabled={disabled}
              {...props}
              placeholder={placeholder}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.2rem !important",
                  height: "5rem !important",
                },
              }}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

Times.propTypes = {
  value: PropTypes.string,
  // label: PropTypes.string,
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  children: PropTypes.node,
  name: PropTypes.string,
  onBlur: PropTypes.func,
};

const TimePicker = (props) => {
  const { name, label, placeholder, ...rest } = props;
  const classes = useStyles();
  return (
    <Grid container direction="column" gap={1}>
      <FormLabel className={classes.FormLabel}>{label}</FormLabel>
      <Field
        name={name}
        as={Times}
        {...rest}
        placeholder={placeholder}
        className={classes.input}
      />
      <ErrorMessage name={name} component={TextError} />
    </Grid>
  );
};

TimePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
};

export default TimePicker;
