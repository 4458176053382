import React, { useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { Box, Drawer, Toolbar, CssBaseline } from "@mui/material";

export const AppContainer = ({ container, children }) => {
    const drawerWidth = 200;
    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Header
          handleDrawerToggle={handleDrawerToggle}
          drawerWidth={drawerWidth}
        />
        <Box
          component="nav"
          sx={{
            width: { lg: "30rem" },
            backgroundColor: "#f8f8f8",
            flexShrink: { md: 0 },
          }}
          aria-label="sidebar_menu"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", lg: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "30rem",
              },
              "& .MuiBackdrop-root": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <SideNav types="temporary" handleDrawerToggle={handleDrawerToggle} />
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", lg: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "#f8f8f8",
              },
              "& .MuiBackdrop-root": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
              },
            }}
            open
          >
            <SideNav />
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flex: 1,
            p: 3,
            width: "100%" /* { xs: `calc(100% - ${drawerWidth}px)` } */,
  
            backgroundColor: "#f8f8f8",
          }}
        >
          <Toolbar />
          {children}
        </Box>
      </Box>
    );
  };