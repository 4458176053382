import Lab from "components/Icons/LabIcon";
import Medical from "components/Icons/Medical";
import PlansIcon from "components/Icons/PlansIcon";
import PeopleIcon from "components/Icons/PeopleIcon";
import DoctorsIcon from "components/Icons/DoctorsIcon";
import FinanceIcon from "components/Icons/FinanceIcon";
import PatientsIcon from "components/Icons/PatientsIcon";
import PartnersIcon from "components/Icons/PartnersIcon";
import SettingsIcon from "components/Icons/SettingsIcon";
import DashboardIcon from "components/Icons/DashboardIcon";
import RefferalsIcon from "components/Icons/RefferalsIcon";
import SubscriptionIcon from "components/Icons/SubscriptionIcon";
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";
import EventBusySharpIcon from "@mui/icons-material/EventBusySharp";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import LabelIcon from "components/Icons/LabelIcon";

export const pharmacyMenu = [
  { id: 0, title: "Dashboard", icon: <DashboardIcon />, path: "/dashboard" },
  {
    id: 1,
    title: "Pending Orders",
    icon: <AccessTimeFilledIcon />,
    path: "/pending-order",
  },
  {
    id: 2,
    title: "Processing Orders",
    icon: <UpdateRoundedIcon />,
    path: "/processing-order",
  },
  {
    id: 3,
    title: "Completed orders",
    icon: <CheckCircleSharpIcon />,
    path: "/completed-order",
  },
  {
    id: 5,
    title: "Cancelled Orders",
    icon: <EventBusySharpIcon />,
    path: "/cancelled-order",
  },

  { id: 11, title: "Settings", icon: <SettingsIcon />, path: "/settings" },
];
export const diagnosticsMenu = [
  { id: 0, title: "Dashboard", icon: <DashboardIcon />, path: "/dashboard" },
  {
    id: 1,
    title: "Test Referrals",
    icon: <AccessTimeFilledIcon />,
    path: "/pending",
  },
  {
    id: 2,
    title: "Scheduled Tests",
    icon: <InsertInvitationOutlinedIcon />,
    path: "/schedule",
  },
  {
    id: 3,
    title: "Completed Tests",
    icon: <CheckCircleSharpIcon />,
    path: "/completed",
  },

  {
    id: 5,
    title: "Cancelled Tests",
    icon: <EventBusySharpIcon />,
    path: "/cancelled",
  },

  { id: 11, title: "Settings", icon: <SettingsIcon />, path: "/setting" },
];

export const hospitalMenu = [
  {
    id: 0,
    title: "Dashboard",
    icon: <DashboardIcon />,
    path: "/dashboard",
  },
  {
    id: 1,
    title: "Patients",
    icon: <PatientsIcon />,
    path: "/patients",
  },
  {
    id: 2,
    title: "Doctors",
    icon: <DoctorsIcon />,
    path: "/hcps",
  },
  {
    id: 20,
    title: "Validations",
    icon: <PeopleIcon />,
    path: "/validations",
  },
  // {
  //   id: 23,
  //   title: "Tests Referrals",
  //   icon: <Lab />,
  //   path: "/tests",
  // },
  // {
  //   id: 230,
  //   title: "Medication",
  //   icon: <Medical />,
  //   path: "/medical",
  // },

  {
    id: 8,
    title: "Finance",
    icon: <FinanceIcon />,
    path: "/finance",
  },
  {
    id: 9,
    title: "Referrals",
    icon: <RefferalsIcon />,
    path: "/referrals",
  },
  {
    id: 10,
    title: "Subscription Plans",
    icon: <SubscriptionIcon />,
    path: "/plans",
  },
  {
    id: 11,
    title: "Settings",
    icon: <SettingsIcon />,
    path: "/settings",
  },
];

export const hmoMenu = [
  {
    id: 0,
    title: "Dashboard",
    icon: <DashboardIcon />,
    path: "/dashboard",
  },
  {
    id: 1,
    title: "Patients",
    icon: <PatientsIcon />,
    path: "/patients",
  },
  {
    id: 2,
    title: "Enrollees",
    icon: <PartnersIcon />,
    path: "/enrollees",
  },
  {
    id: 3,
    title: "Companies",
    icon: <PartnersIcon />,
    path: "/companies",
  },
  {
    id: 4,
    title: "Consultations",
    icon: <LabelIcon />,
    path: "/consultations",
  },
/*   {
    id: 8,
    title: "Benefits List",
    icon: <LabelIcon />,
    path: "/benefits-list",
    disabled: true,
  },
  {
    id: 9,
    title: "Claims Automation",
    icon: <RefferalsIcon />,
    path: "/claims-automation",
    disabled: true,
  },
  {
    id: 10,
    title: "Payouts",
    icon: <FinanceIcon />,
    path: "/payouts",
    disabled: true,
  }, */
  {
    id: 12,
    title: "Plans",
    icon: <PlansIcon />,
    path: "/plans",
  },
  {
    id: 11,
    title: "Settings",
    icon: <SettingsIcon />,
    path: "/settings",
  },
];
